<template>
  <div>
<div v-if="viewings != null && viewings.length > 0 && HasActiveViewings(listing.viewings)" class="viewings">
  <div v-if="viewings.length > 0" class="label">Visningar</div>
  <template v-for="(viewing,index) in viewings" >
    <div class="date row" :key="viewing.id" v-if="new Date(viewing.startTime).getTime() > new Date().getTime() && viewing.showOnInternet && (showAllViewings || index < noOfViewings)">
      <div class="col-3 text-center position-relative">
        <div class="blue-date-square position-absolute left-0 px-2 py-1">
          <span class="d-md-block">{{new Date(viewing.startTime).getDate()}}</span>
          <span class="d-md-block px-2 px-md-0">{{monthNames[new Date(viewing.startTime).getMonth()]}}</span>
        </div>
      </div>
      <div class="col-9 info-label" v-if="GetTime(viewing.startTime) == '0:00' && GetTime(viewing.endTime) == '0:00'">
        
        <a v-if="content.apiId == 'sthlm'" target="_blank" :href="'https://bokavisning.maklare.vitec.net/M24135/'+listing.estateId+'/'+viewing.id">Anmälan krävs<br>
        Kontakta mäklare</a>
      </div>
      <div class="col-9 info-label" v-else>
        <!-- <a href="#" v-on:click="ShowModal($event)">{{viewing.commentary}}Boka visning</a><br> -->
        <a v-if="content.apiId == 'sthlm' && viewing.commentary != ''" target="_blank" :href="'https://bokavisning.maklare.vitec.net/M24135/'+listing.estateId+'/'+viewing.id">{{viewing.commentary}}</a>
        <br />
        {{DayOfTheWeek(new Date(viewing.startTime)) }} {{GetTime(viewing.startTime)}}-{{GetTime(viewing.endTime)}}
      </div>
    </div>
  </template>
  <div class="d-block w-100 h-100 p-2" style="background-color: #fff;" v-if="viewings.length > noOfViewings">
  <a href="#" v-if="!showAllViewings" v-on:click="ShowAllViewings($event)">Visa alla visningar +</a>
  <a href="#" v-else v-on:click="ShowAllViewings($event)">Visa färre visningar -</a>
  </div>
</div>
<BookingModal :show="showModal" title="Boka visning" id="bookViewingModal" :estateId="listing.estateId" :content="content"></BookingModal>
</div>
</template>
<script>
import BookingModal from '@/components/BookingModal.vue';
export default {
  name: 'ViewingBox',
  props: {
    listing:Object,
    content:Object
  },
  data: function(){
    return{
      monthNames:["Jan","Feb","Mar","Apr","Maj","Jun","Jul","Aug","Sep","Okt","Nov","Dec"],
      showModal:false,
      noOfViewings:2,
      showAllViewings:false,
      viewings:[]
    }
  },
  created:function(){
    for(let viewing of this.listing.viewings){
      if(new Date(viewing.startTime).getTime() > new Date().getTime() && viewing.showOnInternet){
          this.viewings.push(viewing);
        }
    }
  },
  components:{
    BookingModal
  },
   methods: {
    ShowAllViewings(e){
      e.preventDefault();
      this.showAllViewings = !this.showAllViewings;
    },
    HasActiveViewings(viewings){
      let hasActiveViewing = false;
      for(const viewing of viewings){
        if(new Date(viewing.startTime).getTime() > new Date().getTime() && viewing.showOnInternet){
          hasActiveViewing = true;
        }
      }
      return hasActiveViewing;
    },
    GetTime(date){
      var datetime = new Date(date);
      return datetime.getHours()+':'+(datetime.getMinutes()<10?'0':'') + datetime.getMinutes();
    },
    DayOfTheWeek(date){
      return date.toLocaleDateString('sv-SE', {
        weekday: 'short',
      });
    },
    ShowModal(e){
      e.preventDefault();
      this.showModal = !this.showModal;
    }
    }
}
</script>
<style lang="scss">
.viewings{
  a{
    color:#333;
  }
  .label{
    display:block;
    width:100%;
    height: 30px;
    padding-top:5px;
    color:#fff;
    font-weight:600;
    text-align: center;
    &.label-bid{
      background-color: #d9ae8b;
    }
    background-color: #5bb1b2;
  }
  .blue-date-square{
    background-color: #dff2f0;
    color:#000;
    font-weight:600;
  }
  .date{
    margin:unset;
    padding:0.5rem;
    // border:1px solid black;
    background-color: #fff;
  }
  .info-label{
    a{
      color:#2c3e50;
    }
    text-align:left;
    font-weight:400;
  }
  .expand-rows{
    text-decoration: underline;
    cursor:pointer;
  }
}
</style>

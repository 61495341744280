//import Vue from 'vue'
import { createApp,h } from 'vue'
import App from './App.vue'
//import router from './router/spain'
import router from './router'
import VueGtag from "vue-gtag";
import '@popperjs/core'
import 'bootstrap'
import "@fontsource/jost";
import "@fontsource/istok-web";
import "@fontsource/poppins";
import "@fontsource/bodoni-moda";
import vue3PhotoPreview from 'vue3-photo-preview';
import 'vue3-photo-preview/dist/index.css';

//import VueLazyLoad from 'vue-lazyload'
//import '@popperjs/core'

//Vue.config.productionTip = false

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')

const app = createApp({
  App,
  router,
  render: ()=>h(App)
});
app.use(router);
app.use(vue3PhotoPreview);
app.use(VueGtag,{
  config: { id: "UA-122492762-2" }
},router);
app.mount('#app');

<template>
  <div class="container-fluid">
   <div class="contact-teaser position-fixed row d-flex align-items-center justify-content-center" v-if="teaser && showTeaser">
    <div class="contact-content position-relative w-50 px-5 py-3 d-flex">
      <button type="button" class="btn-close position-absolute" aria-label="Close" style="float:right;" v-on:click="disableTeaser"></button>
      <span class="title">Hur kan vi hjälpa dig?</span>
      <a href="#contact-form" class="button black">Kontakta oss</a>
      <a href="#contact-form" class="button black">Fri värdering</a>
    </div>
   </div>
   <div id="contact-form" class="banner light-beige two-column-banner row d-flex align-items-center justify-content-center">
        <div class="col-md-6 col-12 d-flex py-5">
          <div class="banner-text">
            <form class="contact-form row">
              <h2 v-if="msg != null">{{msg}}</h2>
              <h2 v-else>Dags att sälja?</h2>
              <p v-if="submsg == null">{{statics['Kontakta oss']}}!</p>
              <p v-else v-html="submsg"></p>
              <div class="col-lg-6 col-12 mb-lg-3 mb-2">
                <input type="text" class="form-control mb-2" v-model="firstname" :placeholder="statics['Förnamn']"/>
                <input type="text" class="form-control my-2" v-model="lastname" :placeholder="statics['Efternamn']"/>
                <input type="phone" class="form-control my-2" v-model="phonenumber" :placeholder="statics['Telefonnummer']"/>
                <input type="email" class="form-control" v-model="email" placeholder="E-post"/>
                <input v-if="hasAddress" type="text" class="form-control" v-model="address" placeholder="Adress"/>
              </div>
              <div class="col-lg-6 col-12 mb-3">
                <textarea class="form-control h-100" v-model="message" rows="7" :placeholder="statics['Meddelande']"></textarea>
              </div>
              
              <div class="col-md-8 col-12">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="policyApprove" id="flexCheckDefault">
                  <label class="form-check-label px-1" for="flexCheckDefault">
                    Jag godkänner Wikingskiöld / Aspelins behandling av mina personuppgifter.
                    <!-- {{statics['Jag godkänner Wikingskiöld / Aspelins behandling av mina personuppgifter.']}}  -->
                    <router-link to="/personuppgifter">Integritetspolicy</router-link>
                    <!-- <a href="https://unikfast.se/Personuppgifter-A453.pdf">{{statics['integritetspolicy']}}</a> -->
                  </label>
                </div>
              </div>
              <div class="col-md-4 col-12 justify-content-right">
              <button :disabled="(!policyApprove || phonenumber == '' || email == '')" :class="(!policyApprove || phonenumber == '' || email == '') ? 'disabled':''" class="button black text-uppercase" v-on:click="submitform">{{statics['Skicka']}}</button>
              {{messageSent}}
              </div>
            </form>
            
          </div>
        </div>
    </div>
  </div>
</template>

<script>
//var statics = require('@/helpers/lang_en');
const axios = require('axios');
export default {
  name: 'ContactForm',
  props: {
    msg: String,
    submsg: String,
    estateId: String,
    broker: Object,
    content: Object,
    hasAddress: Boolean,
    showTeaser: Boolean
  },
  created:function(){
    var statics = require('@/helpers/lang_'+this.content.lang);
    this.statics = statics.lang;
  },
  data: function(){
    return{
      statics:{},
      firstname:'',
      lastname:'',
      phonenumber:'',
      email:'',
      message:'',
      messageSent:'',
      policyApprove:false,
      teaser:true
    }
  },
  methods:{
    disableTeaser(){
      this.teaser = false;
      document.cookie = "unik-teaser=1; expires=Thu, 18 Dec 2100 12:00:00 UTC";
    },
    submitform(e){
      e.preventDefault();
      var self = this;
      var _firstName = self.firstname;
      var _lastName = self.lastname;
      var _email = self.email;
      var _phoneNumber = self.phonenumber;
      let url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/contact/send_mail";
      let email = (self.broker && self.broker.medarbetareInformation.email) ? self.broker.medarbetareInformation.email : "info@wafast.se";
      
      axios.post(url, {
        firstname:self.firstname,
        lastname:self.lastname,
        phonenumber:self.phonenumber,
        message:self.message,
        email: self.email,
        url:self.$router.currentRoute.value.fullPath,
        broker:email,
        estateId: self.estateId
      })
      .then(function (response) {
        console.log(response.data);
        self.firstname = '';
        self.lastname = '';
        self.phonenumber = '';
        self.message = '';
        self.email = '';
        self.messageSent = self.statics['Meddelande har skickats!'];
      })
      .catch(function (error) {
        self.messageSent = self.statics['Något gick fel!'];
        console.log(error);
      });
      if(self.estateId != null && self.estateId != ''){
        url = process.env.VUE_APP_URL+"/api/contact/send_interest_vitec?estateId="+self.estateId;
        axios.post(url, {
          firstname:_firstName,
          lastname:_lastName,
          phonenumber:_phoneNumber,
          email:_email
        })
        .then(function (response) {
          console.log(response);
          _firstName = '';
          _lastName = '';
          _phoneNumber = '';
          _email = '';
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/globals/styles/colorPalette";
.contact-teaser{
  z-index:400;
  bottom:0;
  
  width:100%;
  .contact-content{
    @media (max-width: 767.98px) {
      width:100% !important;
    }
    .btn-close{
      right:10px;
    }
    .title{
      font-size:20px;
    }
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #aca499;
    color:$white;
    flex-direction: column;
  }
}
.form-check-input:checked[type=checkbox] {
    background-color: #000;
}
#contact-form{
  z-index:500;
  // border-top: 2px #e4e4e4 solid;
  // border-bottom: 2px #e4e4e4 solid;
}
.contact-form{
  
  // a{
  //   color:$white;
  // }
  text-align:left;
  color:#494441;
  input,textarea{
    background-color: $main-white;
    padding:0.7rem;
    border:none;
    color:#494441;
    &::placeholder{
      color:#333;
      opacity:0.5
    }
  }
}
.container-fluid.no-padding{
  padding:0;
}
.two-column-banner.banner{
  padding:0;
}
.button.black{
  float:right;
  background:$main-dark-beige;
  color:#fff;
  @media (min-width: 767.98px) {
    padding:1rem;
  }
  
  padding-left:3rem;
  padding-right:3rem;
  &.disabled{
    opacity: 0.5;
  }
  /* width:150px; */
}
.banner .banner-text{
  z-index:50;
  text-align:left;
  // padding:2rem;
  // @media (min-width: 700px) and (orientation: landscape) {
  //   padding:5rem; 
  // }
}

</style>

<template>
   <div class="listing-item justify-content-center">
        <!-- <router-link :to="lang+city+'/'+getPropertyType(listingItem.type)+'/'+slugify(listingItem.streetAddress)+'/'+listingItem.id"> -->
        <router-link :to="'/till-salu/'+listingItem.id">
            <div class="img-container">
                <!-- <span v-if="listingItem.status.id == sold || listingItem.status.id == soldReference" class="price-label">SÅLD</span> -->
                <span v-if="listingItem.price > 0 " class="price-label">{{NumberFormat(listingItem.price)}} {{currency ? currency:"kr"}}</span>
                <span v-else-if="listingItem.type != 'project' && content.apiId != 'marbella'" class="price-label">Pris saknas</span>
                
                <img v-if="listingItem.mainImage != null && listingItem.mainImage.imageId != null" loading="lazy" height="300"
                :alt="listingItem.streetAddress"
                :src="app_url+'/api/'+content.apiId+'/listings/get_img/?imageId='+listingItem.mainImage.imageId+'&width=800'"/>
                <img v-else-if="listingItem.pictures && listingItem.pictures.Picture[0].SrcSet" :srcset="listingItem.pictures.Picture[0].SrcSet" :src="listingItem.pictures.Picture[0].PictureURL" :alt="listingItem.pictures.Picture[0].PictureCaption"  loading="lazy"  height="300"/>
                <img v-else-if="listingItem.pictures" :src="listingItem.pictures.Picture[0].PictureURL" :alt="listingItem.pictures.Picture[0].PictureCaption"  loading="lazy"  height="300"/>
                <span v-if="listingItem.livingSpace" class="object-info-label">{{listingItem.livingSpace}} {{statics['kvm']}} <span v-if="listingItem.rooms">{{listingItem.rooms}} ROK</span></span>
                <span class="object-info-label bid" v-if="listingItem.bidding && listingItem.status.id != sold && listingItem.status.id != soldReference">Budgivning pågår</span>
            </div>
            <div class="object-info">
                <span v-if="listingItem.areaName != null" class="city">{{listingItem.areaName}}</span><br v-if="listingItem.areaName != null">
                <span class="address">{{listingItem.streetAddress}}</span>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
  name: 'ListingItem',
  data: function(){
    return{
      lang:"",
      app_url:process.env.VUE_APP_URL,
      forSale:"3",
      sold:"4",
      soldReference:"10",
      comming:"2",
      city:"",
      statics:[]
    }
  },
  created: function(){
    if(this.content.apiId == "sthlm"){
        this.city = "/sthlm";
    }
    var statics = require('@/helpers/lang_'+this.content.lang);
    this.statics = statics.lang;
  },
  methods: {
    NumberFormat(sum){
      return Number(sum).toLocaleString('sv');
    },
    getPropertyType(type){
        if(type=="housingCooperativese") return "bostadsratt";
        else if(type=="house") return "hus";
        else if(type=="project") return "nyproduktion";
        else if(type=="cottage") return "fritidshus";
        else if(type=="plot") return "tomt";
        else if(type=="condominium") return "agarlgh"
    },
    slugify(address){
        if(!address) return address;
        address = address.replaceAll(' ','-');
        address = address.replaceAll('/','-');
        address = address.replaceAll('&','-');
        address = address.replaceAll('?','-');
        return address;
    }
  },
  props: {
    content:Object,
    msg: String,
    currency:{
        type:String
    },
    listingItem:{}
    //propertyType:this.listingItem.type == "house" ? "hus" : "object"
  }
}
</script>

<style scoped lang="scss">
@import '@/globals/styles/colorPalette.scss';

.listing-item
{
    a{
        
        text-decoration: none;
        transition: 0.3s;
        position: relative;
        .price-label{
            position: absolute;
            background: rgba(0, 0, 0, 0.5);
            top:5px;
            padding:3px 15px 3px 15px;
            color:#fff;
            font-weight:600;
            z-index:300;
        }
        .object-info-label{
            font-weight:600;
            color:#fff;
            position:absolute;
            background: rgba(0, 0, 0, 0.5);
            bottom:8px;
            right:0;
            padding:3px 15px 3px 15px;
            font-size:10pt;

            &.bid{
                background-color:#d9ae8b;
                right:unset;
                left:0;
            }
        }
        .img-container{
            position: relative;
            box-shadow: 0px 0px 3px 0.1px $main-darkBlue;
            border-radius: 5px;
            height:300px;
            overflow:hidden;
            img{
                height:100%;
                object-fit: cover;
                width:100%;

                -webkit-transform: scale(1);
                transform: scale(1);
                -webkit-transition: .5s ease-in-out;
                transition: .5s ease-in-out;
            }
            &:hover img{
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
            }
        }
        // &:hover .img-container{
        //         box-shadow: 0px 0px 10px 1px $main-yellow;
        //         animation-name: color-border-fadeToDarkBlue ;
        //         animation-duration: 0.5s;
        //     }
        .object-info
        {
            color:#494441;
            // color:#494441;
            padding:1rem;
            text-align:start;
            .city{
            font-size:11pt;
            font-weight:500;
            }
            .address{
                // color:#aea79f;
                font-size:13pt;
                font-weight:600;
            }
            .detail span{
                font-size:7pt;
            }    
        }
    }
}

</style>

let lang = {
    "Kontakta oss":"Contact us",
    "Bli kontaktad för en kostnadsfri värdering":"Contact us to get a free valuation of your home",
    "Bostäder till salu":"Properties for sale",
    "Bostäder att hyra":"Properties for rent",
    "Hittar du något du gillar?":"Find anything you like?",
    "Här ser du några av de senast inkomna bostäderna hos oss. Kanske är någon av dem ert nästa hem?":"Here you can see some of the most recently listed homes with us. Maybe one of them is your next home?",
    "Här är våra utvalda exklusiva hem att hyra":"Here are our selected exclusive homes for rent",
    "Se fler hem":"See more homes",
    "Ska du sälja din bostad?":"Are you selling your home?",
    "Förnamn":"Firstname",
    "Efternamn":"Lastname",
    "Telefonnummer":"Phone",
    "E-post":"Email",
    "Meddelande":"Message",
    "Skicka":"Send",
    "Jag godkänner Unikfasts behandling av mina personuppgifter. Se vår" : "I approve Unikfast's processing of my personal data. See our",
    "integritetspolicy":"policy",
    "Meddelande har skickats!":"Message has been sent!",
    "Något gick fel!":"Something went wrong!",
    "Inga fastigheter":"No properties found",
    "Läs mer":"Read more",
    "Sök":"Search",
    "Alla områden":"All areas",
    "Område":"Area",
    "Sortering":"Sorting",
    "Högst pris":"Highest price",
    "Lägst pris":"Lowest price",
    "Se fler bilder":"See more pictures",
    "Intresseanmälan":"Contact us",
    "Karta":"Map",
    "Pris":"Price",
    "Hyra":"Rent",
    "På begäran":"On request",
    "Rum":"Room",
    "Badrum":"Bathroom",
    "Sovrum":"Bedroom",
    "Boarea":"Livingspace",
    "Vårt":"Our",
    "Team":"Team",
    "Kontakta mig":"Contact me",
    "Dokument":"Document",
    "Nyproduktion":"New developments",
    "kvm":"m²",
    "Terass":"Terrace",
    "Sök på område eller adress":"Search area or address",
    "Beskrivning":"Description",
    "Bilder":"Pictures",
    "Adress, Ort, Mäklare":"Address, City, Broker"
};
exports.lang = lang;
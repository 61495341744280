<template>
    <div id="nav">
        <div class="nav-menu d-flex justify-content-start pt-2">
          <!-- <router-link class="nav-link" to="/"><img class="logo" :src="require(`@/assets/logo/WA-Logo-White-Long.svg`)"></router-link> -->
          <router-link class="nav-link" to="/"><img class="logo" :src="logoSrc"></router-link>
        </div>
        <!-- <i class="bi bi-search" v-on:click="this.$parent.showSearch = !this.$parent.showSearch"></i> -->
        <i v-if="showSideMenu" class="menu bi bi-list" v-on:click="changeState()"></i>
    </div>
</template>

<script>
export default {
  name: 'Nav',
  props: {
    showSideMenu:Boolean,
    content: Object
  },
  computed: {
    logoSrc() {
      // Example condition: change logo based on route
      if (this.$route.path.includes('/till-salu')) {
        //return require('@/assets/logo/WA-Logo-Black-Long.svg');
        return require('@/assets/logo/WA-Logo-White-Long.svg');
      } else {
        return require('@/assets/logo/WA-Logo-White-Long.svg');
      }
    }
  },
   methods: {
    changeState() {
      this.$emit('changeState')
    }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.instagram{
  left:10px;
  top:10px;
  i{
    font-size:24px;
  }
}
.nav-menu{
  z-index:1000;
  color:#fff;
  .nav-link:hover{
    color:#fff;
  }
}
#nav{
  width:100%;
  position:absolute;
}

/* .nav-menu{
  position:absolute;
  left:44%;
} */
.nav-menu a,.nav-menu span{
    color:#fff;
    z-index:1000;
    text-decoration: none;
    /* margin-right:1.5rem; */
    font-weight:normal;
}
// .nav-menu img{
//   width:70px;
// }
.nav-menu a:hover{
    text-decoration: underline;
}
.menu, .bi-search{
    z-index:5;
    position:absolute;
    color:#fff;
    top:0;
    right:10px;
    font-size:30pt;
    /* width:80px; */
}
.bi-search{
  cursor:pointer;
  right:60px;
  top:10px;
  font-size:20pt;
}
</style>

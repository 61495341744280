import ForSale from '../views/ForSale.vue'
//import Intro from '../views/Intro.vue'
import Home from '../views/Home.vue'
import Listing from '../views/Listing.vue'
import Comming from '../views/Comming.vue'
import BrokerDetail from '../views/BrokerDetail.vue'
import AboutUs from '../views/AboutUs.vue'
import Selling from '../views/Selling.vue'
import Default from '../views/Default.vue'
import Contact from '../views/Contact.vue'

const sthlmContent = {
    lang:"sv",
      apiId:"sthlm",
      menu:{
        items:[
          {url:"/",text:"START"},
          {url:"/vara-hem",text:"VÅRA HEM"},
          {url:"/underhand",text:"UNDERHAND"}
        ],
        items2:[
          {url:"/team",text:"VÅRT TEAM"},
          {url:"/kontakta-oss",text:"KONTAKTA OSS"}
        ],
        sidebar:{
          items:[
        ],
          external:[
          ],
          offices:[
          ]
        }
      },
      header:{
        //img:"../assets/newBg.jpg"
      },
      footer:{
        address:"Hagaesplanaden 60, 113 66 Stockholm",
        id:"sthlm"
      },
      title:"Stockholm",
      intro:"Ska du sälja din bostad?",
      metakeywords:"Stockholm mäklare, på gång stockholm, köpa lägenhet stockholm, Bästa mäklare i stockholm, mäklare i stockholm, topp mäklare i stockholm"
  }

  let routes =  [
    {
      path: '/till-salu/:id',
      name: 'ForSale',
      component: ForSale,
      props:{
        content:sthlmContent
      }
    },
    {
      path: '/kontakta-oss',
      name: 'Contact',
      component: Contact,
      props:{
        content:sthlmContent,
        title:"Wikingskiöld / Aspelin",
        description:"Passion för yrket är skillnaden."
        
      }
    },
    {
      path: '/',
      name: 'Sthlm hem',
      component: Home,
      props:{
        content:sthlmContent,
        title:"Wikingskiöld / Aspelin | Stockholm",
        description:"Passion för yrket är skillnaden."
      }
    },
    {
      path: '/vara-hem',
      name: 'Listing',
      component:Listing,
      props:{
        content:sthlmContent,
        title:"Wikingskiöld / Aspelin | Bostäder till salu",
        description:"Passion för yrket är skillnaden."
      }
      
    },
    
    {
      path: '/sthlm/snart-till-salu',
      name: 'Stockholm Snart till salu',
      component:Comming,
      props:{
        content:sthlmContent,
        title:"Wikingskiöld / Aspelin | Bostäder snart till salu",
        description:"Passion för yrket är skillnaden."
      }
    },
    {
      path: '/sthlm/salja',
      name: 'Selling',
      component:Selling,
      props:{
        content:sthlmContent,
        title:"Wikingskiöld / Aspelin | Säljguide",
        description:"Passion för yrket är skillnaden."
      }
      
    },
    {
      path: '/medarbetare/:slug',
      name: 'Sthlm mäklarsida',
      component:BrokerDetail,
      props:{
        content:sthlmContent,
        title:"Wikingskiöld / Aspelin",
        description:"Passion för yrket är skillnaden."
      }
    },
    {
      path: '/team',
      name: 'AboutUs',
      component:AboutUs,
      props:{
        content:sthlmContent,
        title:"Wikingskiöld / Aspelin | VÅRT TEAM",
        description:"Passion för yrket är skillnaden."
      }
    },
    {
      path: '/:slug',
      name: 'Default',
      component:Default,
      props:{
        content:sthlmContent,
        title:"Wikingskiöld / Aspelin",
        description:"Passion för yrket är skillnaden."
      }
    }
    
  ]
  export {routes}
<template>
  <div class="home">
    <Search v-if="showSearch" :content="content"></Search>
    <Nav @changeState="showSideBar=!showSideBar" :showSideMenu="true"/>
    <NavHiddenSideBar v-if="showSideBar" @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    <div id="nav-hidden" class="container-fluid navbar sticky-top" style="display:none;">
      <NavHidden  @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    </div>
    <PropertyDetailInfo v-if="listing !== null" 
    :listing="listing"
    :content="content"
      />
    <Footer :footer="content.footer"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Search from '@/components/Search.vue'
import PropertyDetailInfo from '@/components/PropertyDetailInfoNew.vue'
import NavHidden from '@/components/NavHidden.vue'
import NavHiddenSideBar from '@/components/NavHiddenSideBar.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
const axios = require('axios');
import { useRoute } from 'vue-router';

export default {
  name: 'PropertyDetail',
  components: {
    PropertyDetailInfo,
    NavHiddenSideBar,NavHidden,Nav,Footer,Search
  },
  props:{
    content:Object,
    title: String,
    description: String,
    id: String
  },
  data: function(){
    return{
      //activeSlide:0,
      //showGallery:false,
      app_url:process.env.VUE_APP_URL,
      listing:null,
      showSideBar:false,
      showSearch:false
    }
  },
  created: function(){
    var self = this;
    const route = useRoute();
    let objectId = route.params.id;
    //let objectId = this.$route.params.id;
    
    let url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/listings/getlistingdetails?id="+objectId
        axios.get(url)
        .then(function(response){
          self.listing = response.data;
        }).catch(function(){
          
          self.$router.push("/404");
        });
        //commercialPropertys
  },
  // created: function(){
  //   this.getListing();
  // },
  methods: {
    // toggleGallery(index){
    //   this.activeSlide = index;
    //   console.log(index);
    //   this.showGallery=!this.showGallery;
    // },
    getListing(){
      var self = this;
      const route = useRoute();
      const url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/listings/getpropertylisting?estateId="+route.params.objectId
        axios.get(url)
        .then(function(response){
          if(response.data.assignment.status.id == "9" || 
          response.data.assignment.status.id == "6" ||
          response.data.assignment.status.id == "7"){
            self.$router.push("/404");
          }
          else self.listing = response.data;
        }).catch(function(){
          document.location = "/404";
        });
    }
  }
}
</script>

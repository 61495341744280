<template>
   <div class="broker-item">
        <router-link :to="'/medarbetare/'+broker.slug" class="row" :class="theme ? theme:''">
            <div class="broker-container overflow-hidden col-md-6">
                <img class="" v-if="broker.medarbetareInformation.profileImage" 
                :srcset="broker.medarbetareInformation.profileImage.srcSet" 
                :src="broker.medarbetareInformation.profileImage.sourceUrl"/>
            </div>
            <div class="object-info col-md-6">
                <div class="row detail">
                    <div class="col-12 text-md-start"><h5>{{broker.title}}</h5></div>
                    <div class="col-12 text-md-start"><span>{{broker.medarbetareInformation.title}}</span></div>
                    <div class="col-12 text-md-start"><span>{{broker.medarbetareInformation.phone}}</span></div>
                    <div class="col-12 text-md-start"><span>{{broker.medarbetareInformation.email}}</span></div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
  name: 'BrokerPuffBlend',
  props: {
    content:Object,
    broker:Object,
    theme:String
  }
}
</script>
<style scoped lang="scss">
.broker-item{
    a{
        &.dark{
            color:#ccc8bd;
        }
        color:#494441;
        text-decoration: none;
        .broker-container{
         
        //  overflow: hidden;
         img{
            @media (min-width: 768px) {
                height: 330px;
            }
            max-height: 400px;
            // object-fit:contain;
            object-position:top;
         }
        }
    }
}

.object-info{
    padding:1rem;
    //text-align: center;
    .city{
    font-size:10pt;
    }
    .address{
        font-size:14pt;
        font-weight:600;
    }
    .detail span{
        font-size:10pt;
    }
}

</style>

<template>
  <div>
    <div class="banner-overlay position-fixed" style="z-index:600;background: rgba(0, 0, 0, 0.9);"></div>
    
    <div class="search-overlay position-fixed w-100 h-100">
      <i class="bi bi-x-lg position-absolute color-white" v-on:click="this.$parent.showSearch = !this.$parent.showSearch"></i>
      <div class="container h-100">
        <div class="row h-100 justify-content-center flex-direction: column">
          <div class="col-12 col-md-4 d-flex align-items-center">
            <div class="container">
              <div class="row position-relative">
                <!-- <span class="color-white">Sök</span><br> -->
                <input ref="search" :autofocus="this.$parent.showSearch" type="text" v-model="query" class="w-100 search-input p-2" v-on:keyup="Search" :placeholder="statics['Adress, Ort, Mäklare']"/>
                <!-- <i class="bi bi-x-lg position-absolute color-white" v-on:click="this.$parent.showSearch = !this.$parent.showSearch"></i> -->
                <div class="col-12 result-area color-white position-absolute">
                  <ul class="result-list pl-0">
                    <li v-for="item in result" :key="item">
                      <a :href="item.url">{{ item.name }}</a>
                      <!-- <img v-if="item.mainImage && item.mainImage.url" :src="item.mainImage.url" > -->
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Search',
  props: {
    content:Object
  },
  data: function(){
    return{
      result:[],
      statics:[],
      query:"",
      listing:[],
      locations:[],
      wp_brokers:[],
      lang:"",
      city:"",
      brokerUrl:"maklare"
    }
  },
  created: function(){
    
    var statics = require('@/helpers/lang_'+this.content.lang);
    this.statics = statics.lang;
    // if(this.content.lang == "en"){
    //     //this.lang = "/en/";
    // }
    // else if(this.content.lang == "es"){
    //     this.lang = "/es/";
    //     this.listing = "viviendas-en-venta";
    // }
    // else if(this.content.lang == "sv"){
    //     this.lang = "/sv/";
    // }
    if(this.content.apiId == 'marbella' && this.content.lang == "en"){
        this.lang = "";
        this.city = "";
        this.brokerUrl = "broker";
    }
    else if(this.content.apiId == 'marbella' && this.content.lang == "sv"){
        this.lang = "/sv";
        this.city = "";
        this.brokerUrl = "maklare";
    }
    else if(this.content.apiId == 'marbella' && this.content.lang == "es"){
        this.lang = "/es";
        this.city = "";
        this.brokerUrl = "corredor";
    }
    else{
        this.city = "/"+this.content.apiId;
    }
    if(this.content.apiId == 'marbella'){
      this.getListing();
      this.getCRMListing();
    } 
    else this.getSwedenListing();
    this.getCustomBrokers();
    //this.getRentalListing();
  },
  methods: {
    Search(){
      this.result = [];
      if(this.query != '' && this.query.length > 2){
        for(let item of this.listing){
          if(item.streetAddress != null && item.streetAddress.toLowerCase().indexOf(this.query.toLowerCase()) > -1){
            if(this.content.apiId == 'marbella'){
              this.result.push({name:item.streetAddress,url:"/property/"+item.streetAddress+"/"+item.id,mainImage:item.mainImage});
            }
            else this.result.push({name:item.streetAddress,url:this.city+"/"+this.getPropertyType(item.type)+"/"+this.slugify(item.streetAddress)+"/"+item.id});
          }
          else if(item.areaName != null && item.areaName.toLowerCase().indexOf(this.query.toLowerCase()) > -1){
            if(this.content.apiId == 'marbella'){
              this.result.push({name:item.streetAddress,url:"/property/"+item.streetAddress+"/"+item.id,mainImage:item.mainImage});
            }
            else this.result.push({name:item.streetAddress,url:this.city+"/"+this.getPropertyType(item.type)+"/"+this.slugify(item.streetAddress)+"/"+item.id});
          }
        }
        for(let item of this.wp_brokers){
          if(item.title.toLowerCase().indexOf(this.query.toLowerCase()) > -1){
            this.result.push({name:item.title,url:this.city+"/"+this.brokerUrl+"/"+item.slug,mainImage:{url:item.medarbetareInformation.profileImage.sourceUrl}});
          }
        }
      }
    },
    getCustomBrokers(){
      var self = this;
      let categoryId = 4;
      if(this.content.apiId === "sthlm"){
        categoryId = 2;
      }
      else if(this.content.apiId === "umea"){
        categoryId = 3;
      }
      else if(this.content.apiId === "marbella"){
        categoryId = 4;
      }
      else if(this.content.apiId === "vasteras"){
        categoryId = 8;
      }
        axios.post('https://unikfast.se/cms/graphql', {
          query: "{employees(first:50,where:{categoryIn:"+categoryId+"}){ nodes{title,slug,content,medarbetareInformation{phone,email,title,vitecUser,profileImage{id,srcSet,sourceUrl},headerImage{id,srcSet,sourceUrl},teaserImage{id,srcSet,sourceUrl}},featuredImage{node{id,sourceUrl}}} }}"
        }).then(function(response){
            self.wp_brokers = response.data.data.employees.nodes;
        });
    },
    getSwedenListing(){
      var self = this;
      this.listing = [];
      let url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/listings/getall";
      if(self.content.apiId == 'vasteras') url += "?customerId=M14463";
        axios.get(url)
        .then(function(response){
          self.loading=false;
          let allListings = response.data;
          for(let item of allListings){
            self.listing.push(item);
          }
        });
    },
    getCRMListing(){
      //this.days = days;
      var self = this;
      //var crmListings = [];
      axios.get(process.env.VUE_APP_URL+"/api/abroad/getcrmlistings").then(function(response){
            //self.wp_brokers = response.data.data.employees.nodes;
            for(var property of response.data){
              //if(self.locations.indexOf(property.resales.area) === -1) self.locations.push(property.resales.area);
              let p = {
                    areaName:property.areaName ? property.areaName.name:'',
                    streetAddress : property.streetAddress ? property.streetAddress.name:'',
                    livingSpace: property.livingSpace,
                    mainImage:property.mainImage.url,
                    pictures:{Picture:[{PictureURL:property.mainImage.url,SrcSet:null}]},
                    id:property.reference,
                    //id:property.reference,
                    //bidding:null,
                    price:property.price,
                    status:{
                      id:"3"
                    },
                    //bedrooms:property.Bedrooms,
                    //bathrooms:property.Bathrooms,
                    //rooms:null,
                    //dateChanged:null,
                    type:"marbella",
                    hide:false
                };
              self.listing.push(p);
              //self.chunk.push(p);
            }
            //self.listings = self.listings.concat(crmListings);
            //self.chunk = self.listings;
            //console.log(self.listings);
        });
      
    },
    getListing(){
      //this.days = days;
      var self = this;

      axios.post('https://unikfast.se/cms/graphql', {
          query: "{posts(first:100,where:{categoryIn:[5,6]}){ nodes{title,slug,content,resales{reference,price,kvm,area},featuredImage{node{id,srcSet,sourceUrl}}} }}"
        }).then(function(response){
            //self.wp_brokers = response.data.data.employees.nodes;
            for(var property of response.data.data.posts.nodes){
              if(self.locations.indexOf(property.resales.area) === -1) self.locations.push(property.resales.area);
              let p = {
                    areaName:property.resales.area,
                    streetAddress : property.title,
                    livingSpace: property.resales.kvm,
                    mainImage:{url:property.featuredImage.node.sourceUrl},
                    pictures:{Picture:[{PictureURL:property.featuredImage.node.sourceUrl,SrcSet:property.featuredImage.node.srcSet}]},
                    //id:property.resales.reference,
                    id:property.slug,
                    //bidding:null,
                    price:property.resales.price ? parseInt(property.resales.price):property.resales.price,
                    status:{
                      id:"3"
                    },
                    //bedrooms:property.Bedrooms,
                    //bathrooms:property.Bathrooms,
                    //rooms:null,
                    //dateChanged:null,
                    type:"marbella",
                    hide:false
                }
              self.listing.push(p);
              //self.chunk.push(p);
            }
            //self.chunk = self.listings;
            //self.chunk = self.chunk.concat(self.listing);
            //console.log(self.listings);
            //self.getCRMListing();
        });
      
    },
    getPropertyType(type){
        if(type=="housingCooperativese") return "bostadsratt";
        else if(type=="house") return "hus";
        else if(type=="marbella" && this.content.lang == 'sv') return "bostad";
        else if(type=="marbella" && this.content.lang == 'en') return "property";
        else if(type=="marbella" && this.content.lang == 'es') return "vivienda";
        else if(type=="project") return "nyproduktion"
    },
    slugify(address){
        if(!address) return address;
        address = address.replaceAll(' ','-');
        address = address.replaceAll('/','-');
        address = address.replaceAll('&','-');
        address = address.replaceAll('?','-');
        return address;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/globals/styles/colorPalette.scss';
  .search-input{
    border-radius:4px;
    border:none;
    
    &:hover{
      box-shadow: 0px 0px 10px 1px $main-yellow;
      animation-name: color-border-fadeToDarkBlue ;
      animation-duration: 0.5s;
      border:none;
    }
    &:focus-visible{
      outline:none;
    }
  }
  .bi-x-lg{
    position: absolute;
    // top:150px;
    right:0;
    font-size:30pt;
    cursor:pointer;
  }
  .color-white{
    color:#fff;
    text-transform: uppercase;
  }
  .result-area{
    top:50px;
  }
  .result-list{
    list-style-type: none;
    text-align:left;
    padding-left:0;
    li a{
      color:#fff;
    }
  }

</style>

<template>
<div class="price-box" v-if="!listing.advertiseOn.hidePrice">
    <div class="price-container">
        <div class="price-info row p-2">
            <span v-if="listing.baseInformation && listing.baseInformation.monthlyFee != null" class="price-fee col-12">Månadsavgift: {{NumberFormat(listing.baseInformation.monthlyFee)}} kr/mån</span>
            <div v-if="listing.assignment.status.id == sold || listing.assignment.status.id == soldReference" class="price-label-tag col-12">Slutpris: <span class="price-label">{{NumberFormat(listing.price.finalPrice)}} kr</span></div>
            <div v-else class="price-label-tag col-12">Utgångspris: 
                <span v-if="listing.price.startingPrice > 0" class="price-label">{{NumberFormat(listing.price.startingPrice)}} kr</span>
                <span v-else class="price-label">Pris saknas</span>
            </div>
        </div>
    </div>
    <ViewingBox :listing="listing" :content="content"></ViewingBox>
    <BiddingBox :listing="listing" :content="content"></BiddingBox>
</div>
</template>
<script>
import ViewingBox from '@/components/ViewingBox.vue'
import BiddingBox from '@/components/BiddingBox.vue'
export default {
  name: 'PriceBox',
  props: {
    listing:Object,
    content:Object
  },
  created: function(){
    if(this.content.apiId == "umea"){
      //this.getBroker();
      this.forSale = "2HIII41T24FNHOU2";
      this.sold = "2HIII4T0O9LMQ3UI";
      this.status = this.forSale;
    }
  },
  data: function(){
    return{
      forSale:"3",
      sold:"4",
      soldReference:"10",
      comming:"2",
    }
  },
  components: {
    ViewingBox,
    BiddingBox
    },
   methods: {
    NumberFormat(sum){
      return Number(sum).toLocaleString('sv');
    },
    }
}
</script>
<template>
  <div class="sidebar">
    <span style="float:right;"><i class="bi bi-x-lg" v-on:click="changeState()"></i></span>
    <nav class="nav-menu nav-hidden row text-start" style="margin-top:5rem;">
      <ul>
        <!-- <li class="pb-2"><router-link to="/"><img  class="logo" :src="require(`@/assets/logo/WA-Logo-White.svg`)"></router-link></li> -->
        <li v-for="item in menu.items" :key="item.url"> <router-link :to="item.url" class="col-md-1 col-12" v-on:click="changeState()">{{item.text}}</router-link> </li>
        <li v-for="item in menu.items2" :key="item.url"> <router-link :to="item.url" class="col-md-1 col-12" v-on:click="changeState()">{{item.text}}</router-link> </li>
        <template v-if="menu.external">
          <li v-for="item in menu.external" :key="item.url">
            <a :href="item.url" class="col-md-1 col-12">{{item.text}}</a>
          </li>
      </template>
      <template v-if="menu.sidebar">
        <li v-for="item in menu.sidebar.items" :key="item.url">
            <router-link :to="item.url" class="col-md-1 col-12">{{item.text}}</router-link>
        </li>
        <li v-for="item in menu.sidebar.external" :key="item.url">
          <a :href="item.url" class="col-md-1 col-12">{{item.text}}</a>
        </li>
        <li v-if="menu.sidebar.offices && menu.sidebar.offices.length > 0" class="mt-5">
          <span style="cursor:auto; text-decoration: none;">
            Våra kontor
            <hr class="py-0 mt-0 mb-2">
          </span>
        </li>
        <li v-for="item in menu.sidebar.offices" :key="item.url">
          <a :href="item.url" class="col-md-1 col-12">{{item.text}}</a>
        </li>
        <li>
          <!-- <a href="" class="col-md-1 col-12"></a> -->
          <a target="_blank" href="https://www.instagram.com/wikingskioldaspelin" class="text-white rounded-circle position-absolute instagram"><i class="fa-lg bi bi-instagram" ></i></a>
        </li>
      </template>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'NavHiddenSideBar',
  props:{
    menu:Object
  },
   methods: {
    changeState() {
      this.$emit('changeState')
    }
   }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/globals/styles/colorPalette.scss";
.sidebar{
    padding:2rem;
    position: fixed;
    z-index:1200;
    height:100%;
    /* background: #fff; */
    // background-color: #0a101b;
    background-color:$main-dark-beige;
    right: 0;
    top: 0;
    font-size:14pt;
    // font-weight:600;
    span{
      i.bi{
        // color:#d09658;
        color:#fff;
      }
    }
}
.nav-menu.nav-hidden a,.nav-menu.nav-hidden span {
  z-index:1000;
  // color:#d09658;
  color:#fff;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

.nav-menu.nav-hidden a svg.logo{
    width:200px;
}
img.logo{
  width:200px;
}
.nav-menu{
  ul{
    list-style-type: none;
  }
}
.nav-menu.nav-hidden{
    padding:1rem;
}
</style>

//import Intro from '../views/Intro.vue'
import NotFound from '../views/NotFound.vue'

import { createRouter, createWebHistory } from 'vue-router';

import {routes as sthlmRoutes } from '@/router/sthlm';

const notFoundContentSwedish = {
  lang:"sv",
    apiId:"sthlm",
    menu:{
      items:[
        {url:"/",text:"START"},
        {url:"/vara-hem",text:"VÅRA HEM"},
        {url:"/underhand",text:"UNDERHAND"}
      ],
      items2:[
        {url:"/team",text:"VÅRT TEAM"},
        {url:"/kontakta-oss",text:"KONTAKTA OSS"}
      ],
      sidebar:{
        items:[
      ],
        external:[
          
        ]
      }
    },
    header:{
      //img:"../assets/newBg.jpg"
    },
    footer:{
      address:"Hagaesplanaden 60, 113 66 Stockholm",
      id:"sthlm"
    },
    title:"Stockholm",
    intro:"Ska du sälja din bostad?",
    metakeywords:"Stockholm mäklare, på gång stockholm, köpa lägenhet stockholm, Bästa mäklare i stockholm, mäklare i stockholm, topp mäklare i stockholm",
    metarobots:"noindex"
}

let routes = [
  {
    path: '/404',
    name: '404',
    component: NotFound,
    props:{
      content:notFoundContentSwedish
    }
  },
  { 
    path: '/:pathMatch(.*)', 
    component:NotFound,
    beforeEnter() { window.location.href = "/404" },
    props:{
      content:notFoundContentSwedish
    }
  }
];

routes = routes.concat(sthlmRoutes);


const router = createRouter({
  // Use the HTML HistoryAPI so the # isn't needed in the URL, and
  // Vue routing will work even when going directly to a URL.
  mode: 'history',
  history: createWebHistory(),
  routes,
  scrollBehavior() {
      return { top: 0 }
  }
});

router.beforeEach((to, from, next) => {

  //console.log(to.matched[0].props.default.content);
  if(to.matched[0].props.default.content.metakeywords) document.querySelector('meta[name="keywords"]').setAttribute("content", to.matched[0].props.default.content.metakeywords);
  if(to.matched[0].props.default.description && to.matched[0].props.default.description != '') document.querySelector('meta[name="description"]').setAttribute("content", to.matched[0].props.default.description);
  if(to.matched[0].props.default.title && to.matched[0].props.default.title != '') document.title = to.matched[0].props.default.title;
  if(to.matched[0].props.default.content.metarobots) document.querySelector('meta[name="robots"]').setAttribute("content", to.matched[0].props.default.content.metarobots);
  else document.querySelector('meta[name="robots"]').setAttribute("content", "index, follow");
  next();
});

export default router

<template>
  <div class="row detailed-facts gy-2">
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.baseInformation.tenure"><span>Upplåtelseform:</span><br>{{listing.baseInformation.tenure}}</div>

    <div class="col-lg-3 col-md-4 col-6" v-if="listing.houseInterior"><span>Antal rum:</span><br>{{listing.houseInterior.numberOfRooms}} rum och kök</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.interior"><span>Antal rum:</span><br>{{listing.interior.numberOfRooms}} rum och kök</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.baseInformation && listing.baseInformation.livingSpace"><span>Boarea:</span><br>{{listing.baseInformation.livingSpace}} kvm</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.baseInformation && listing.baseInformation.monthlyFee"><span>Månadsavgift:</span><br>{{listing.baseInformation.monthlyFee}} kr</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.tvAndBroadband && listing.tvAndBroadband.broadband">
      <span>Bredband:</span><br><p class="preline" v-html="listing.tvAndBroadband.broadband"></p>
    </div>

      <div class="col-lg-3 col-md-4 col-6" v-if="listing.energyDeclaration && listing.energyDeclaration.energyClass != 'None'">
        <span>Energiprestanda:</span><br> {{listing.energyDeclaration.energyClass}}
      </div>
      <div class="col-lg-3 col-md-4 col-6" v-if="listing.energyDeclaration && listing.energyDeclaration.energyDeclarationDate">
        <span>Energi deklarationsdatum:</span><br> {{formatDate(listing.energyDeclaration.energyDeclarationDate)}}
      </div>
    <template v-if="listing.building">
      <div class="col-lg-3 col-md-4 col-6" v-if="listing.building.buildingType"><span>Bostadstyp:</span><br>{{listing.building.buildingType}}</div>
      <div class="col-lg-3 col-md-4 col-6" v-if="listing.building.buildingYear"><span>Byggnadsår:</span><br>{{listing.building.buildingYear}}</div>
      <div class="col-lg-3 col-md-4 col-6" v-if="listing.building.commentaryForBuildingYear" v-html="listing.building.commentaryForBuildingYear"></div>
      <div class="col-lg-3 col-md-4 col-6" v-if="listing.building.heating"><span>Värme:</span><br>{{ listing.building.heating }}</div>
      <div class="col-lg-3 col-md-4 col-6" v-if="listing.building.windows"><span>Fönster:</span><br>{{ listing.building.windows }}</div>
      <div class="col-lg-3 col-md-4 col-6" v-if="listing.building.otherAboutTheBuildning"><span>Övrigt om byggnaden:</span><br><p class="preline" v-html="listing.building.otherAboutTheBuildning"></p></div>
      <div class="col-lg-3 col-md-4 col-6" v-if="listing.building.otherBuildings" ><span>Andra byggnader:</span><br><p class="preline" v-html="listing.building.otherBuildings"></p></div>
      <!-- <div class="col-lg-3 col-md-4 col-6" v-if="listing.building.renovations"><span>Renoveringar:</span><br><p v-html="listing.building.renovations"></p></div> -->
    </template>
    
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.baseInformation.propertyType"><span>Objekttyp:</span><br>{{listing.baseInformation.propertyType}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.baseInformation.propertyUnitDesignation"><span>Beteckning:</span><br>{{listing.baseInformation.propertyUnitDesignation}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.baseInformation.other"><span>Övrigt:</span><br>{{listing.baseInformation.other}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.plot && listing.plot.plotType"><span>Tomt:</span><br>{{listing.plot.plotType}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.assess && listing.assess.typeCode"><span>Taxeringskod:</span><br>{{listing.assess.typeCode}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.assess && listing.assess.totalAssessedValue"><span>Taxeringsvärde:</span><br>{{NumberFormat(listing.assess.totalAssessedValue)}} kr</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.assess && listing.assess.buildingValue"><span>Taxeringsvärde för byggnad:</span><br>{{NumberFormat(listing.assess.buildingValue)}} kr</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.assess && listing.assess.taxAssessmentYear"><span>Taxeringsår:</span><br>{{listing.assess.taxAssessmentYear}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.assess && listing.assess.taxFee"><span>Fastighetsskatt:</span><br>{{NumberFormat(listing.assess.taxFee)}} kr</div>

    <div class="col-lg-3 col-md-4 col-6" v-if="listing.leasehold && listing.leasehold.leaseholdFee"><span>Tomträttsavgäld:</span><br>{{listing.leasehold.leaseholdFee}} kr</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.leasehold && listing.leasehold.leaseholdUntil"><span>Tomträttsavgäld gäller t.o.m:</span><br>{{listing.leasehold.leaseholdUntil.substring(0,10)}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.leasehold && listing.leasehold.leaseholdPeriod"><span>Avgäldsperiod:</span><br>{{listing.leasehold.leaseholdPeriod}} år</div>

    <div class="col-lg-3 col-md-4 col-6" v-if="listing.mortages"><span>Pantbrev:</span><br>{{ NumberFormat(MortagesSum(listing.mortages)) }} kr</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.waterAndDrain && listing.waterAndDrain.info"><span>VA:</span><br>{{listing.waterAndDrain.info}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.ventilation && listing.ventilation.type"><span>Ventilationstyp:</span><br>{{listing.ventilation.type}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.ventilation && listing.ventilation.inspection"><span>Ventilationsbesiktning:</span><br>{{listing.ventilation.inspection}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.plot && listing.plot.parking"><span>Beskrivning bilplats:</span><br>{{listing.plot.parking}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.plot && listing.plot.area"><span>Tomtarea:</span><br><b>Tomtarea:</b> {{listing.plot.area}} kvm</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.plot && listing.plot.patio"><span>Beskrivning uteplats:</span><br><p v-html="listing.plot.patio"></p></div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.plot && listing.plot.otherPlot"><span>Övrigt:</span><br>{{listing.plot.otherPlot}}</div>
    <div class="col-lg-3 col-md-4 col-12" v-if="listing.enrollments && listing.enrollments.preferentialAndCommunity"><p class="preline" v-html="listing.enrollments.preferentialAndCommunity"></p></div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.enrollments && listing.enrollments.enrolledEasement">{{listing.enrollments.enrolledEasement}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.enrollments && listing.enrollments.planRegulations">{{listing.enrollments.planRegulations}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.enrollments && listing.enrollments.communityFacilities">{{listing.enrollments.communityFacilities}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.enrollments && listing.enrollments.easementsBenefits">{{listing.enrollments.easementsBenefits}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.enrollments && listing.enrollments.easementsLoad">{{listing.enrollments.easementsLoad}}</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.tvAndBroadband && listing.tvAndBroadband.tv">
      <span>Tv:</span><br><p class="preline" v-html="listing.tvAndBroadband.tv"></p>
    </div>
    
    <template v-if="listing.balconyPatio">
      <div class="col-lg-3 col-md-4 col-6" v-if="listing.balconyPatio.type"><span>Balkongtyp:</span><br> {{listing.balconyPatio.type}}</div>
      <div class="col-lg-3 col-md-4 col-6" v-if="listing.balconyPatio.parkingLot"><span>Parkering:</span><br>{{listing.balconyPatio.parking}}</div>
    </template>
    <template v-if="listing.operation && listing.operation.sum > 0">
      <div class="row mx-0 p-2 border-beige">
        <h5 class="text-center text-decoration-underline">Driftkostnader</h5>
        
        <div class="col-lg-3 col-md-4 col-6" v-if="listing.operation.heating"><span>Uppvärmning:</span><br> {{listing.operation.heating}} kr/år</div>

        <div class="col-lg-3 col-md-4 col-6" v-if="listing.operation.electricity && path.indexOf('hus') > 0"><span>El:</span><br> {{listing.operation.electricity}} kr/år</div>
        <div class="col-lg-3 col-md-4 col-6" v-else-if="listing.operation.electricity"><span>El:</span><br> {{NumberFormat(listing.operation.electricity)}} kr/år</div>

        <div class="col-lg-3 col-md-4 col-6" v-if="listing.operation.waterAndDrain"><span>VA:</span><br> {{NumberFormat(listing.operation.waterAndDrain)}} kr/år</div>
        <div class="col-lg-3 col-md-4 col-6" v-if="listing.operation.insurance"><span>Försäkring:</span><br> {{NumberFormat(listing.operation.insurance)}} kr/år</div>
        <div class="col-lg-3 col-md-4 col-6" v-if="listing.operation.chimneySweeping"><span>Sotning:</span><br>Sotning: {{listing.operation.chimneySweeping}} kr/år</div>
        <div class="col-lg-3 col-md-4 col-6" v-if="listing.operation.roadCommunity"><span>Väg / Samfällighetsavgift:</span><br> {{listing.operation.roadCommunity}} kr/år</div>
        <div class="col-lg-3 col-md-4 col-6" v-if="listing.operation.maintenance"><span>Underhåll:</span><br> {{listing.operation.maintenance}}</div>
        <div class="col-lg-3 col-md-4 col-6" v-if="listing.operation.other"><span>Övrigt:</span><br> {{listing.operation.other}} kr/år</div> 
        <div class="col-lg-3 col-md-4 col-6" v-if="listing.operation.personsInTheHousehold"><span>Personer i hushållet:</span><br> {{listing.operation.personsInTheHousehold}}</div>
        <div class="col-lg-3 col-md-4 col-6" v-if="listing.operation.sanitation"><span>Renhållning:</span><br> {{NumberFormat(listing.operation.sanitation)}} kr/år</div>
        
        <div class="col-lg-3 col-md-4 col-6" v-if="listing.operation.sum && path.indexOf('hus') > 0"><span>Totala driftkostnader:</span><br> {{listing.operation.sum}} kr/år</div>
        <div class="col-lg-3 col-md-4 col-6" v-else-if="listing.operation.sum"><span>Totala driftkostnader:</span><br> {{NumberFormat(listing.operation.sum)}} kr/år</div>

        <div class="col-lg-3 col-md-4 col-12" v-if="listing.operation.commentary"><span>Kommentar:</span><br><p class="preline" v-html="listing.operation.commentary"></p></div>
      </div>
   </template>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.participationAndRepairFund && listing.participationAndRepairFund.indirectNetDebt && listing.participationAndRepairFund.indirectNetDebt > 0"><span>Nettoskuldsättning:</span><br> {{NumberFormat(listing.participationAndRepairFund.indirectNetDebt)}} kr</div>
    <div class="col-lg-3 col-md-4 col-6" v-if="listing.participationAndRepairFund && listing.participationAndRepairFund.indirectNetDebtComment"><span>Nettoskuldsättning kommentar:</span><br> {{ listing.participationAndRepairFund.indirectNetDebtComment }}</div>
    <!-- <div class="col-md-3" v-if="listing.advertiseOn.documents != null && listing.advertiseOn.documents.length > 0">
      <span>Dokument:</span><br>
      <div class="card card-body document-links">
          <a target="_blank" :href="app_url+'/api/'+content.apiId+'/documents/get_doc/?docId='+document.id" 
          v-for="document in listing.advertiseOn.documents" 
          :key="document.id">
          <i class="bi bi-file-pdf"></i> {{document.name}}
          </a>
        </div>
    </div> -->
    <!-- <div class="col-md-6" v-if="listing.surrounding && listing.surrounding.generalAboutArea">
      <span class="text-decoration-underline">Allmänt om området</span>
      <p v-html="listing.surrounding.generalAboutArea"></p>
    </div> -->
    
    <!-- 
      <p><b>Andel i Föreningen</b>: <?php echo $property_details->vParticipationInAssociation; ?></p> -->
    
    </div>
</template>
<style lang="scss" scoped>
.border-beige{
  border:1px solid #ccc8bd;
}
  .detailed-facts{
    span{
      
      text-decoration: underline;
    }
  }
  .object-description{
  span{
    font-weight: bold;
  }
}
.preline{
  white-space: pre-line;
}
</style>
<script>
//import { useRoute } from 'vue-router';
export default {
  name: 'Generalinfo',
  props: {
    listing: Object
  },
  data: function(){
    return{
      path:this.$route.name
    }
  },
  methods:{
    MortagesSum(mortages){
      let sum = 0;
      for(let mortgage of mortages){
        sum += mortgage.amount;
      }
      return sum;
    },
    NumberFormat(sum){
      return Number(sum).toLocaleString('sv');
    },
    formatDate(timestamp) {
      return new Date(timestamp).toLocaleDateString('sv-SE');
    }
  }
}
</script>
<template> 
<div class="col-md-8 col-12 mt-3">
    <div class="bg-color" style="background-color:#131625; height:50vh;">
        <img class="banner-bg" :src="data.featuredImage.node.guid" :srcset="data.featuredImage.node.srcSet"/>
        <div class="banner-overlay"></div>
        <div class="banner-center d-flex align-items-center justify-content-center" style="height:100%;">
            <div class="banner-container p-3 position-relative">
                <div class="banner-text white text-center position-absolute w-100">
                    <h2 class=" text-uppercase">{{ data.title }}</h2>
                    <p class="mb-4">En diskret process</p>
                    <router-link :to="'/underhand'" class="button whites mt-5" style="text-decoration:none">LÄS MER</router-link>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    export default {
    name: 'Teaser',
    props: {
        data: Object
    }
    }
</script>
<style lang="scss" scoped>
.banner-container{
    z-index:10;
    // background-color:#ccc;
    background: rgba(240, 240, 240, 0.5);
    width:100%;
    height:170px;
}
.teaser-container{
    background-color: #000;
    color:#fff;
}
.bg-color {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh; /* Or as needed */
    background-color: #131625;
}
.banner-center {
    width: 100%; /* Ensures it takes the full width of its parent */
    height: 100%; /* Stretches to the full height of its parent */
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-text {
    left:0;
    z-index:11;
    color:#fff;
    text-align: center; /* Centers the text within the banner-text div */
}
</style>

  
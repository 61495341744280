<template>
  <div class="header">
    <h1 class="text-uppercase">{{ msg }}</h1>
    <p v-if="!text">
      Nedan finnes våra senast inkomna bostäder – välkommen att hitta hem!
    </p>
    <p v-else>
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String,
    text:String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header{
  margin-top:2rem;
  margin-bottom:2rem;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
  li {
    display: inline-block;
    margin: 0 10px;
    a {
      color: #42b983;
    }
  }
}


</style>

<template>
   <div class="listing-item justify-content-center">
        <router-link :to="'/medarbetare/'+broker.slug">
            <div class="broker-image">
                <img :alt="broker.slug" v-if="broker.medarbetareInformation.profileImage" :srcset="broker.medarbetareInformation.profileImage.srcSet" :src="broker.medarbetareInformation.profileImage.sourceUrl"/>
                <img :alt="broker.slug" v-else :srcset="broker.medarbetareInformation.teaserImage.srcSet" :src="broker.medarbetareInformation.teaserImage.sourceUrl"/>
            </div>
            <div class="object-info">
                <span class="address">{{broker.title}}</span>
                <div class="row detail justify-content-center">
                    <div class="col-12"><span>{{broker.medarbetareInformation.title}}</span></div>
                    <div class="col-12"><span style="overflow-wrap:break-word;">{{broker.medarbetareInformation.email}}</span></div>
                    <div class="col-12"><span>{{broker.medarbetareInformation.phone}}</span></div>
                    <!-- <div v-if="content.apiId == 'sthlm'" class="col-7"><span>08 - 409 454 10</span></div> -->
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
  name: 'BrokerPuff',
  data: function(){
    return{
      app_url:process.env.VUE_APP_URL,
      lang:"",
      city:"",
      brokerUrl:"maklare"
    }
  },
  props: {
    msg: String,
    content:Object,
    broker:Object
  },
  created:function(){
    
    
    this.city = "/"+this.content.apiId;
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/globals/styles/colorPalette";
.listing-item{
    height:100%;
    background-color:#fff;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 15%);
    a{
        color:#494441;
        text-decoration: none;
        transition: 0.3s;
        position: relative;
    }
    .broker-image{
        overflow: hidden;
        height:450px;
        border-bottom:5px $main-light-beige solid;

        img{
            width:100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            // scale:0.8;

            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .5s ease-in-out;
            transition: .5s ease-in-out;
        }
        &:hover img{
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
    }
    .object-info{
        padding:1rem;
        background-color:#fff;
        text-align: center;

        .address{
            font-size:14pt;
            font-weight:600;
        }
        .detail span{
            font-size:10pt;
        }
    }
}
</style>

<template>
  <div class="listing-page">
    <Search v-if="showSearch" :content="content"></Search>
    <Nav @changeState="showSideBar=!showSideBar" :showSideMenu="true" :content="content"/>
    <NavHiddenSideBar v-if="showSideBar" @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    <div id="nav-hidden" class="container-fluid navbar sticky-top" style="display:none;">
      <NavHidden @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    </div>
    <div class="carousel-container">
      <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="overlay d-flex align-items-center justify-content-center">
              <!-- <div class="banner-overlay"></div> -->
              <img v-if="content.apiId == 'sthlm'" :src="require(`@/assets/44.jpg`)"/>
              <div class="teaser-container">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container search-area mb-5">
      <div class="row">
        <div class="col-12 mt-3">
          <div class="position-relative">
            <div class="input p-2 w-100 position-absolute" style="">
              <input type="text" class="form-control float-start" style="width:80%;" placeholder="Sök på område eller adress" v-model="searchQuery" @keyup="filterItems()">
              <button type="button" class="btn btn-dark col-2 float-start" style="width:20%;" v-on:click="advancedSearch = !advancedSearch">
                <span class="d-block"><i :class="!advancedSearch ? 'float-mb-end bi bi-filter':'bi bi-x'"></i></span>
              </button>
              <div class="row p-3 text-start" v-if="advancedSearch">
                <div class="col-md-4">
                  Bostadstyp
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="0" v-model="type" checked v-on:change="filterItems()">
                    <label class="form-check-label" for="flexRadioDefault1">
                      Alla
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" value="housingCooperativese" v-model="type"  v-on:change="filterItems()">
                    <label class="form-check-label" for="flexRadioDefault2">
                      Lägenheter
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" value="house" v-model="type"  v-on:change="filterItems()">
                    <label class="form-check-label" for="flexRadioDefault2">
                      Villor
                    </label>
                  </div>
                  <!-- <input class="form-check-input d-none" type="radio" name="flexRadioDefault" value="comming" v-model="type" /> -->
                  <!-- Visa snart till salu<br /> -->
                  <!-- <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" value="comming" v-model="type"  v-on:change="filterItems()">
                    <label class="form-check-label" for="flexRadioDefault2">
                      <i class="bi bi-building-fill-up"></i>
                    </label>
                  </div> -->
                  
                </div>
                <div class="col-md-4">
                  Antal rum
                  <select v-model="numberOfRooms" class="form-select mb-2" aria-label="room select" v-on:change="filterItems()">
                    <option selected value="0">Alla</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                  </select>
                  Område
                  <select class="form-select mb-2" v-model="area" aria-label="area select" @change="filterItems()">
                    <option selected value="all">Alla områden</option>
                    <option :value="area" v-for="area in areas" :key="area">{{area}}</option>
                  </select>
                </div>
                <div class="col-md-4">
                  Sortering
                  <select class="form-select mb-2 listing-filter" v-model="sorting" aria-label="Default select example" @change="filterItems()">
                    <option :selected="content.apiId == 'sthlm'" value="highest" >Högst pris</option>
                    <option value="lowest">Lägst pris</option>
                    <option :selected="content.apiId == 'umea'" value="newest">Senaste</option>
                  </select>
                  <br/>
                  <!-- <button type="button" class="btn btn-dark" v-on:click="seeComming">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building-fill-up" viewBox="0 0 16 16">
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.354-5.854 1.5 1.5a.5.5 0 0 1-.708.708L13 11.707V14.5a.5.5 0 0 1-1 0v-2.793l-.646.647a.5.5 0 0 1-.708-.708l1.5-1.5a.5.5 0 0 1 .708 0Z"></path>
                    <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7.256A4.493 4.493 0 0 0 12.5 8a4.493 4.493 0 0 0-3.59 1.787A.498.498 0 0 0 9 9.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .39-.187A4.476 4.476 0 0 0 8.027 12H6.5a.5.5 0 0 0-.5.5V16H3a1 1 0 0 1-1-1V1Zm2 1.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5Zm3 0v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z"></path>
                  </svg>
                    <span v-if="type != 'comming'"> Visa snart till salu</span>
                    <span v-else> Dölj snart till salu</span> 
                  </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="container">
      <div class="row listing justify-content-center">
        <div v-if="chunk.length == 0 && loading" class="col-12 position-relative py-5">
          <Circle color="#d09658"></Circle>
        </div>
        <div v-else-if="chunk.length == 0" class="col-12 position-relative py-3">
          <p>
            Inga fastigheter
          </p>
        </div>
        <template v-for="(listingitem, index) in chunk" >
          <div class="col-md-6 col-12 mt-3" 
          v-if="index < noOfItems"  
          :key="listingitem.id">
            <ListingItem :listingItem="listingitem" :content="content"/>
          </div>
        </template>
      </div>
      <button v-if="noOfItems < chunk.length" class="button black" v-on:click="getMoreItems()">SE FLER HEM</button>
    </div>
    <!-- <div class="banner beige-dark">
      <div class="container d-flex align-items-center justify-content-center">
        <div class="row">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="banner-text color-white">
              <h2 class="animate__animated black animate__bounce">Om Wikingskiöld / Aspelin</h2>
              <p v-if="aboutText" v-html="aboutText"></p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <ContactForm :content="content" submsg="Går du i säljtankar, vill ha en värdering eller söker efter ditt nästa drömboende? Kontakta oss."/>
    <Footer :footer="content.footer"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Search from '@/components/Search.vue'
import ListingItem from '@/components/ListingItem.vue'
import ContactForm from '@/components/ContactForm.vue'
import NavHidden from '@/components/NavHidden.vue'
import NavHiddenSideBar from '@/components/NavHiddenSideBar.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
// import {Circle} from 'vue-loading-spinner';
import Circle from 'vue-spinner/src/PulseLoader.vue'
//import { createPopper } from '@popperjs/core';
const axios = require('axios');
//const moment = require('moment');
export default {
  name: 'Listing',
  components: {
    ListingItem,ContactForm,
    NavHiddenSideBar,NavHidden,Nav,Footer,
    Circle,
    Search
  },
  props:{
    content:Object,
    title: String,
    description: String
  },
  data: function(){
    return{
      advancedSearch:false,
      app_url:process.env.VUE_APP_URL,
      listing:[],
      numberOfRooms:0,
      //apartments:[],
      //houses:[],
      comming_properties:[],
      mixed:[],
      chunk:[],
      areas:[],
      searchQuery:"",
      area:"all",
      sorting:"highest",
      type:0,
      //status:'3',
      forSale:"3",
      sold:"4",
      comming:"2",
      status:"3",
      noOfItems:50,
      showSideBar:false,
      loading:true,
      showSearch:false,
      aboutText:""
    }
  },
  created: function(){
    this.getListing();
    //this.getAboutText();
  },
  methods: {
    getAboutText(){
      let self = this;
      const postSlug = 'om-wafast-ab'; // replace with your actual post slug

axios.post('https://easypress.se/wafast/graphql', {
    query: `{
      posts(where: {name: "${postSlug}"}) {
        nodes {
          title,
          slug,
          content,
          featuredImage {
            node { id, sourceUrl }
          }
        }
      }
    }`
}).then(function(response) {
  if(response.data.data.posts.nodes && response.data.data.posts.nodes.length == 1){
    self.aboutText = response.data.data.posts.nodes[0].content;
  }
    
});

    },
    seeComming(){
      if(this.type != 'comming') this.type = "comming";
      else this.type = 0;
      this.filterItems();
    },
    getListing(){
      var self = this;
      let url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/listings/getalldb";
      if(self.content.apiId == 'vasteras') url += "?customerId=M14463";
      axios.get(url)
        .then(function(response){
          let i = 0;
          self.loading = false;
          for(var list of response.data){
            if(self.areas.indexOf(list.areaName) === -1 && list.areaName !== '' && list.areaName != null) self.areas.push(list.areaName);
          }
          self.areas.sort(function (a, b) {
                if (a > b) {
                  return 1;
                }
                if (a < b) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });

          //self.getAreas();
          //self.chunk = self.listing.slice(0,50);
          
          for(let item of response.data){
              self.listing.push({
                id:item.estateId,
                //areaName:item.baseInformation.objectAddress.area,
                areaName:item.areaName,
                //livingSpace:item.baseInformation.livingSpace,
                livingSpace:item.livingSpace,
                //mainImage:item.images[0],
                mainImage:item.mainImage,
                //streetAddress:item.baseInformation.objectAddress.streetAddress,
                streetAddress:item.streetAddress,
                price:item.price.startingPrice,
                status:item.assignment.status,
                type:item.subType == 'House' ? 'house' : 'housingCooperativese',
                //rooms:item.houseInterior && item.houseInterior.numberOfRooms ? item.houseInterior.numberOfRooms : item.interior && item.interior.numberOfRooms ? item.interior.numberOfRooms : '',
                rooms:item.rooms
              });
            }
            self.chunk = self.listing;
            /*
            if(list.status.id == self.status){
              self.mixed.push(list);
              self.listing.push(list);
            }
            */
            
        });

    },
    /*
    getListing(){
      var self = this;
      let url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/listings/getall";
      if(self.content.apiId == 'vasteras') url += "?customerId=M14463";
        axios.get(url)
        .then(function(response){
          self.loading=false;
          //let allListings = response.data;
          for(var list of response.data){
            if(self.areas.indexOf(list.areaName) === -1 && list.areaName !== '' && list.areaName != null) self.areas.push(list.areaName);
            if(list.status.id == self.status){
              self.mixed.push(list);
              self.listing.push(list);
            }
          }
          self.areas.sort(function (a, b) {
                if (a > b) {
                  return 1;
                }
                if (a < b) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });

          //self.getAreas();
          //self.chunk = self.listing.slice(0,50);
          self.chunk = self.listing;
          if(self.content.apiId == "umea"){
            axios.get(process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/listings/getcomminglistings")
          .then(function(response){
            self.comming_properties = response.data;
          });
          }else{
            let url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/listings/getcomminglistings";
            if(self.content.apiId == 'vasteras') url += "?customerId=M14463";
            axios.get(url)
          .then(function(response){
            for(let item of response.data){
              self.comming_properties.push({
                id:item.estateId,
                //areaName:item.baseInformation.objectAddress.area,
                areaName:item.areaName,
                //livingSpace:item.baseInformation.livingSpace,
                livingSpace:item.livingSpace,
                //mainImage:item.images[0],
                mainImage:item.mainImage,
                //streetAddress:item.baseInformation.objectAddress.streetAddress,
                streetAddress:item.streetAddress,
                price:item.price.startingPrice,
                status:item.assignment.status,
                type:item.subType == 'House' ? 'house' : 'housingCooperativese',
                //rooms:item.houseInterior && item.houseInterior.numberOfRooms ? item.houseInterior.numberOfRooms : item.interior && item.interior.numberOfRooms ? item.interior.numberOfRooms : '',
                rooms:item.rooms
              });
            }
            // for(let mixedItem of allListings){
            //   for(let list of response.data){
            //     if(list.estateId === mixedItem.id){
            //       self.comming_properties.push(mixedItem);
            //     }
            //   }
            // }
          });
          }
          
        });
    },
    */
    sort(order){
      if(order == "highest"){
      this.chunk.sort(function (a, b) {
                if (a.price > b.price) {
                  return -1;
                }
                if (a.price < b.price) {
                  return 1;
                }
                // a must be equal to b
                return 0;
              });
      }
      else if(order == "lowest"){
      this.chunk.sort(function (a, b) {
                if (a.price > b.price) {
                  return 1;
                }
                if (a.price < b.price) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
      }
      else if(order == "newest"){
      this.chunk.sort(function (a, b) {
                if (a.dateChanged > b.dateChanged) {
                  return -1;
                }
                if (a.dateChanged < b.dateChanged) {
                  return 1;
                }
                // a must be equal to b
                return 0;
              });
      }
    },
    filterItems(){
      // if(this.type == "comming"){
      //   this.status = this.comming;
      // }
      // else{
      //   this.status = this.forSale;
      // }
      this.chunk = [];
      //this.status = "all";
      var self = this;
      // Search query
      for(var list of this.listing){
        if(list.streetAddress.toLowerCase().indexOf(self.searchQuery.toLowerCase()) > -1 ||
        (list.areaName != null && list.areaName.toLowerCase().indexOf(self.searchQuery.toLowerCase()) > -1)){
          if(
            (this.area == 'all' || this.area == list.areaName) && 
            (this.numberOfRooms == 0 || this.numberOfRooms == list.rooms) &&
            (this.type == 0 || this.type == list.type) //&&
            //(this.status == list.status.id)
            ){
            self.chunk.push(list);
          }
          
        }
      }
      // Add comming to the list
      // if(this.type == 0 || this.status == this.comming){
      //   for(var comming of this.comming_properties){
      //     if(comming.streetAddress.toLowerCase().indexOf(self.searchQuery.toLowerCase()) > -1 ||
      //     (comming.areaName != null && comming.areaName.toLowerCase().indexOf(self.searchQuery.toLowerCase()) > -1)){
      //       if(
      //         (this.area == 'all' || this.area == comming.areaName) && 
      //         (this.numberOfRooms == 0 || this.numberOfRooms == comming.rooms) &&
      //         ((self.searchQuery != '' || this.type == "comming") || (this.status == comming.status.id))
      //         ){
      //         self.chunk.push(comming);
      //       }
      //     }
      //   }
      // }
      this.sort(this.sorting);
    },
    getMoreItems(){
      //this.chunk.push(this.listing.splice(this.chunk.length,this.chunk.length + 10));
      //this.chunk = this.chunk.concat(this.listing.splice(this.chunk.length, 10));
      this.noOfItems = this.noOfItems + 12;
      //this.chunk.push(...this.listing.slice(this.chunk.length, this.chunk.length+10));
    },
    // getAreas(){
    //   var self = this;
    //   const url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/listings/getallareas";
    //     axios.get(url)
    //     .then(function(response){
    //       self.areas = response.data;
    //     });
    // }
  }
}
</script>
<style lang="scss" scoped>
@import '@/globals/styles/colorPalette.scss';
@keyframes example {
  from {width: 100px;}
  to {width: 300px;}
}
// .rotate{
//   transform: rotate(90deg);
//   -webkit-transform: rotateX(180deg);
//           transform: rotateX(180deg);
//   //transition:transform 0.5s;
// }
// span:not(.rotate){
//   -webkit-transform: rotateX(180deg);
//           transform: rotateX(180deg);
//   transition:transform 0.5s;
// }
.category-links ul{
  list-style-type: none;
  
  li{
    font-size:13pt;
    span{
      vertical-align: middle;
      font-size:18pt;
    }
    &.active{
    text-decoration: underline;
    }
    a{
    color:#333;
    text-decoration: none;
    text-transform: uppercase;
    
      &:hover,&:focus,&:active{
        text-decoration: underline;
      }
    }
  } 
}

.listing-page select.listing-filter.form-select{
  background-color: transparent;
  border:1px solid #ccc;
  text-transform: uppercase;
  font-size:11pt;
}
.btn-group button{
  background:none;
  color:#333;
}
.overlay{
  max-height:400px;
  min-height: 200px;
}
.search-area{
  .input{
    box-shadow: 0 6px 20px rgb(0 0 0 / 15%);
    top:-25px;background-color:#fff;z-index:600;
    input:not(.form-check-input){
      border:none;
    }
  }
}

</style>

<template>
  <div class="home">
    <Search v-if="showSearch" :content="content"></Search>
    <Nav @changeState="showSideBar=!showSideBar" :showSideMenu="true" :content="content" />
    <NavHiddenSideBar v-if="showSideBar" @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    <div id="nav-hidden" class="container-fluid navbar sticky-top" style="display:none;">
      <NavHidden @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    </div>
    <div class="carousel-container row px-0 m3-0" style="">
      <div id="carouselExampleIndicators" style="position:fixed;top:0;" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="3000">
        <div class="carousel-inner">
          <div class="carousel-item" :class="index == 0 ? 'active':''" v-for="(image,index) in gallery">
            <div class="overlay d-flex align-items-center justify-content-center">
              <div class="banner-overlay"></div>
              <img :src="image.guid" :srcset="image.srcSet"/>
              <!-- <video autoplay muted loop playsinline id="myVideo">
                <source src="https://easypress.se/wafast/wp-content/uploads/sites/19/2024/01/istockphoto-1711644675-640_adpp_is.mp4" type="video/mp4">
              </video> -->
              <div class="teaser-container">
                <!-- <h3 class="h3-animation">
                  <span>{{content.intro}}</span>
                </h3> -->
                <p>
                  ”Vi är stolta över att alltid tillhandahålla högsta möjliga kundservice, <br>
samtidigt som vi aldrig avviker från våra kärnvärden:<br>
Högsta kvalitét, engagemang och tillgänglighet.”
                </p>
                <a href="#contact-form" class="button">KONTAKTA OSS</a>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
    <div class="container-fluid pb-5 bg-color">
      <div class="row" style="">
        <div class="col-12">
          <Header msg="VÅRA HEM"/>
        </div>
      </div>
      <div class="row justify-content-center listing my-2">
        <div v-if="listings.length == 0" class="col-12 position-relative py-5">
          <Circle color="#d09658"></Circle>
        </div>
        <div class="col-md-5 col-12 mt-3" v-for="listing in listings" :key="listing.estateId">
          <ListingItem :listingItem="listing" :content="content"/>
        </div>
        <!-- <div class="col-md-5 col-12 mt-3" v-for="listing in comming_properties" :key="listing.estateId">
          <ListingItem :listingItem="listing" :content="content"/>
        </div> -->
      </div>
      <router-link :to="'/vara-hem'" class="button black mb-5">SE FLER HEM</router-link>
    </div>
    <div class="container-fluid pb-5 bg-color">
      
      <div class="row" v-if="projectListings.length > 0">
        <div class="col-12">
          <Header msg="Nyproduktion" text="Här ser du några av de senaste inkomna nyproduktion." />
        </div>
      </div>
      <div class="row justify-content-center listing my-2" v-if="projectListings.length > 0">
        <div class="col-md-5 col-12 mt-3" v-for="listing in projectListings" :key="listing.estateId">
          <ListingItem :listingItem="listing" :content="content"/>
        </div>
      </div>
      <!-- Teasers -->
      
      <div class="row justify-content-center" v-if="pageData && pageData.customFields && pageData.customFields.teasers">
        <template v-for="teaser in pageData.customFields.teasers" >
          <Teaser v-if="pageData" :data="teaser.post"></Teaser>
        </template>
        
      </div>
    </div>
    <!-- <div class="banner desktop bg-color" style="background-color:#131625;">
      <div class="banner-overlay"></div>
      <div class="container d-flex align-items-center justify-content-center">
        <div class="row justify-content-center">
          <div class="col-md-8 col-12 d-flex align-items-center ">
            <div class="banner-text white">
              <h2>Om Wikingskiöld / Aspelin</h2>
              <p v-if="aboutText" v-html="aboutText"></p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="container-fluid bg-color">
      <BrokerBanner :content="content"/>
    </div> -->
    <ContactForm :content="content" :show-teaser="false"/>
    <Footer :footer="content.footer" />
    <!-- <ContactModal id="contact" title="Bli kontaktad för en fri värdering" :show="true"></ContactModal> -->
  </div>
</template>

<script>
// @ is an alias to /src
//import { Modal } from "bootstrap";
import Teaser from '@/components/Teaser.vue'
import Search from '@/components/Search.vue'
import Header from '@/components/Header.vue'
import ListingItem from '@/components/ListingItem.vue'
import ContactForm from '@/components/ContactForm.vue'
//import BrokerBanner from '@/components/BrokerBanner.vue'
import NavHidden from '@/components/NavHidden.vue'
import NavHiddenSideBar from '@/components/NavHiddenSideBar.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
//import ContactModal from '@/components/ContactModal.vue'
import Circle from 'vue-spinner/src/PulseLoader.vue'
const axios = require('axios');
export default {
  name: 'Home',
  props:{
    content:Object,
    title: String,
    description: String
  },
  components: {
    ListingItem,
    //ContactModal,
    //BrokerBanner,
    Teaser,
    Search,
    ContactForm,Header,
    NavHiddenSideBar,NavHidden,Nav,Footer,Circle
  },
  data: function(){
    return{
      showTeaser:false,
      app_url:process.env.VUE_APP_URL,
      listings:[],
      comming_properties:[],
      projectListings:[],
      showSideBar:false,
      status:"3",
      showSearch:false,
      aboutText:"",
      gallery:[],
      pageData:null
    }
  },
  mounted: function(){
    // var myModal = new Modal(document.getElementById('contact'), {
    //     keyboard: false
    //   })
    //   myModal.show();
  },
  created: function(){
    let x = document.cookie;
    if(x.indexOf('unik-teaser') < 0){
        this.showTeaser = true;
    }
    this.getListing();
    //this.getCommingListing();
    this.getAboutText();
    this.getHomeContent();
  },
  methods: {
    getHomeContent(){
      let self = this;
      try {
        let slug = "home";
        axios.post(`https://easypress.se/wafast/graphql`, {
      query: `
        query GetPageBySlug($slug: String!) {
          pageBy(uri: $slug) {
			title
            content
			featuredImage {
				node{
					sourceUrl
				}
            }
			customFields{
				topImageAlignment
				topVideo{
					id
					guid
					title
					srcSet
              	}
				gallery{
					id
					guid
					title
					srcSet
				}
				showContactForm
				showContactButton
				teasers{
					post {
					... on Post {
						id
						slug
						title
						customFields{
						icon
						}
						featuredImage {
						node {
							guid
							sourceUrl
							srcSet
						}
						}
						content
						excerpt
					}
					}
				}
				
			}
			
          }
		  
        }
		
      `,
      variables: {
        slug: slug,
      },
    }).then(function(response){
      if(response.data.data.pageBy.customFields && response.data.data.pageBy.customFields.gallery) self.gallery = response.data.data.pageBy.customFields.gallery;
      self.pageData = response.data.data.pageBy;
    });
  }catch(e){
console.log(e);
  }
    },
    getAboutText(){
      let self = this;
      const postSlug = 'om-wafast-ab'; // replace with your actual post slug

axios.post('https://easypress.se/wafast/graphql', {
    query: `{
      posts(where: {name: "${postSlug}"}) {
        nodes {
          title,
          slug,
          content,
          featuredImage {
            node { id, sourceUrl }
          }
        }
      }
    }`
}).then(function(response) {
  if(response.data.data.posts.nodes && response.data.data.posts.nodes.length == 1){
    self.aboutText = response.data.data.posts.nodes[0].content;
  }
    
});

    },
    getProjectListing(){
      var self = this;
      let url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/listings/getall";
        axios.get(url)
        .then(function(response){
          for(var list of response.data){
            if(list.type == "project" && list.status.id == "2N53D7DM4007N9FU"){
              self.projectListings.push(list);
            }
          }
        });
    },
    getListing(){
      var self = this;
      let url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/listings/getalldb";
      if(self.content.apiId == 'vasteras') url += "?customerId=M14463";
      axios.get(url)
        .then(function(response){
          let i = 0;
          
          for(let item of response.data){
              self.listings.push({
                id:item.estateId,
                //areaName:item.baseInformation.objectAddress.area,
                areaName:item.areaName,
                //livingSpace:item.baseInformation.livingSpace,
                livingSpace:item.livingSpace,
                //mainImage:item.images[0],
                mainImage:item.mainImage,
                //streetAddress:item.baseInformation.objectAddress.streetAddress,
                streetAddress:item.streetAddress,
                price:item.price.startingPrice,
                status:item.assignment.status,
                type:item.subType == 'House' ? 'house' : 'housingCooperativese',
                //rooms:item.houseInterior && item.houseInterior.numberOfRooms ? item.houseInterior.numberOfRooms : item.interior && item.interior.numberOfRooms ? item.interior.numberOfRooms : '',
                rooms:item.rooms
              });
            }
            /*
            self.listings.sort(function (a, b) {
              if (a.price < b.price) {
                return 1;
              }
              if (a.price > b.price) {
                return -1;
              }
              // a must be equal to b
              return 0;
            });
            */
            /*
          for(const listing of response.data){
              if(listing.status.id == self.status){
                self.listings.push(listing);
                i++;
                if(i == 6) break;
              }
          }*/
        });

    },
    getCommingListing(){
      var self = this;
      let url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/listings/getcomminglistings";
            axios.get(url)
          .then(function(response){
            for(let item of response.data){
              self.comming_properties.push({
                id:item.estateId,
                //areaName:item.baseInformation.objectAddress.area,
                areaName:item.areaName,
                //livingSpace:item.baseInformation.livingSpace,
                livingSpace:item.livingSpace,
                //mainImage:item.images[0],
                mainImage:item.mainImage,
                //streetAddress:item.baseInformation.objectAddress.streetAddress,
                streetAddress:item.streetAddress,
                price:item.price.startingPrice,
                status:item.assignment.status,
                type:item.subType == 'House' ? 'house' : 'housingCooperativese',
                //rooms:item.houseInterior && item.houseInterior.numberOfRooms ? item.houseInterior.numberOfRooms : item.interior && item.interior.numberOfRooms ? item.interior.numberOfRooms : '',
                rooms:item.rooms
              });
            }
    });
  },
    shuffle(array) {
      let currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
    getActiveClass(index){
      return index == 0 ? 'active' : '';

    }
  }
}
</script>
<style lang="scss" scoped>
.carousel-container{
  --bs-gutter-x:0;
  height: 900px;
  // @media (max-width: 700px) and (orientation: portrait) {
  //   height: 400px;
  // }
}
</style>

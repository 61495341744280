let lang = {
    "Kontakta oss":"Contáctenos",
    "Bli kontaktad för en kostnadsfri värdering":"Contáctenos para obtener una valoración gratuita de su casa",
    "Bostäder till salu":"Propiedades en venta",
    "Bostäder att hyra":"Propiedades en alquiler",
    "Hittar du något du gillar?":"¿Encuentras algo que te gusta?",
    "Här ser du några av de senast inkomna bostäderna hos oss. Kanske är någon av dem ert nästa hem?":"Aquí puede ver algunas de las casas más recientemente listadas con nosotros. ¿Quizás una de ellas sea su próxima casa?",
    "Här är våra utvalda exklusiva hem att hyra":"Aquí están nuestras viviendas exclusivas seleccionadas en alquiler",
    "Se fler hem":"Ve más casas",
    "Ska du sälja din bostad?":"¿Vas a vender tu casa?",
    "Förnamn":"Nombre",
    "Efternamn":"Apellido",
    "Telefonnummer":"Teléfono",
    "E-post":"Email",
    "Meddelande":"Mensaje",
    "Skicka":"Enviar",
    "Jag godkänner Unikfasts behandling av mina personuppgifter. Se vår" : "Acepto el procesamiento de mis datos personales por parte de Unikfast. Ver nuestro",
    "integritetspolicy":"política",
    "Meddelande har skickats!":"¡El mensaje ha sido enviado!",
    "Något gick fel!":"¡Algo salió mal!",
    "Inga fastigheter":"No se encontraron propiedades",
    "Läs mer":"Leer más",
    "Sök":"Buscar",
    "Alla områden":"Todas las áreas",
    "Område":"Area",
    "Sortering":"Sorteo",
    "Högst pris":"Precio más alto",
    "Lägst pris":"Precio más bajo",
    "Se fler bilder":"Ver más fotos",
    "Intresseanmälan":"Contáctenos",
    "Karta":"Mapa",
    "Pris":"Precio",
    "Hyra":"Alquiler",
    "På begäran":"Bajo pedido",
    "Rum":"Habitación",
    "Badrum":"Baños",
    "Sovrum":"Dormitorio",
    "Boarea":"Espacio habitable",
    "Vårt":"Nuestro",
    "Team":"Equipo",
    "Kontakta mig":"Contácta me",
    "Dokument":"Documentos",
    "Nyproduktion":"Obra nueva",
    "kvm":"m²",
    "Terass":"Terraza",
    "Sök på område eller adress":"Busca area o dirección",
    "Beskrivning":"Descripcion",
    "Bilder":"Fotos",
    "Adress, Ort, Mäklare":"Dirreccion, Ciudad, Agente"
};
exports.lang = lang;
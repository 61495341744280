<template>
  <div class="broker-detail-page">
    <Search v-if="showSearch" :content="content"></Search>
    <Nav @changeState="showSideBar=!showSideBar" :showSideMenu="true" :content="content"/>
    <NavHiddenSideBar v-if="showSideBar" @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    <div id="nav-hidden" class="container-fluid navbar sticky-top" style="display:none;">
      <NavHidden @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    </div>
    <div class="carousel-container">
      <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel" v-if="broker">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="overlay d-flex align-items-center justify-content-center" v-if="broker.medarbetareInformation != null && broker.medarbetareInformation.headerImage != null">
              <div class="banner-overlay"></div>
              <img class="header-image" :style="broker.medarbetareInformation.headerImagePosition ? broker.medarbetareInformation.headerImagePosition:''" v-if="broker.medarbetareInformation != null && broker.medarbetareInformation.headerImage != null" :src="broker.medarbetareInformation.headerImage.sourceUrl"/>
              <h1 style="z-index:1000" class="text-white position-absolute h1-animation"><span>{{broker.title}}</span></h1>
            </div>
            <div class="overlay d-flex align-items-center justify-content-center" style="background-color:#131625;height:50vh;" v-else>
              <!-- <div class="banner-overlay"></div> -->
              <h1 style="z-index:1000" class="text-white position-absolute h1-animation"><span>{{broker.title}}</span></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container pb-5">
      <div class="row d-flex justify-content-center mt-3" v-if="broker">
        <div class="col-md-5 pt-5 broker-description">
          <h3 class="text-start">{{broker.title}}</h3>
          <h5 class="text-start">{{broker.medarbetareInformation.title}}</h5>
          <a v-if="broker.medarbetareInformation.phone" :href="'phone:'+broker.medarbetareInformation.phone" class="broker-links"><i class="bi bi-telephone"></i> {{broker.medarbetareInformation.phone}}</a><br>
          <a :href="'mailto:'+broker.medarbetareInformation.email" class="broker-links"><i class="bi bi-envelope"></i> {{broker.medarbetareInformation.email}}</a>
          <p class="text-start mt-2" v-html="broker.content"></p>
        </div>
        <div class="col-md-5">
          <img :srcset="broker.medarbetareInformation.profileImage.srcSet" v-if="broker.medarbetareInformation.profileImage" class="multiply w-100" :src="broker.medarbetareInformation.profileImage.sourceUrl"/>
          <img :srcset="broker.medarbetareInformation.teaserImage.srcSet" v-else-if="broker.medarbetareInformation.teaserImage" class="multiply w-100" :src="broker.medarbetareInformation.teaserImage.sourceUrl"/>
          <img v-else class="multiply w-100" :src="broker.featuredImage.node.sourceUrl"/>
          <div class="d-block position-relative text-center object-description pt-2" v-if="rating">
            <ul class="broker-info mt-2 mb-2">
              <li v-if="rating.overall_rating != 0">
                <i class="bi bi-star mx-1" style="color:#FFCC33" v-for="index in Math.round(rating.overall_rating)" :key="index"></i>
                <!-- <img src="../assets/reco/icon_small_white.png" v-for="index in Math.round(rating.overall_rating)" :key="index"/> -->
              </li>
              <li v-if="rating.overall_rating != 0"><a class="reco-link" target="_blank" :href="rating.url">{{rating.overall_rating.toFixed(1)}}/5 baserat på {{rating.review_count}} omdömen</a></li>
            </ul>
            <div class="col-12">
              <a :href="'mailto:'+broker.medarbetareInformation.email" class="button black">{{statics['Kontakta mig']}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid text-center my-3" v-if="reviews.length > 0">
      <div class="row d-flex justify-content-center mt-3 gx-5 gy-3 px-3 py-4" style="background-color:#f0ecf2">
        <h2 class="font-weight-light">Säljare om {{broker.title}}</h2>
        <div id="recipeCarousel" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner" role="listbox">
              <div :class="index == 0 ? 'active':''" class="carousel-item" v-for="(review,index) in reviews" :key="review.id">
                  <div class="col-md-4 px-3">
                    <p><i class="bi bi-quote"></i>{{review.text}}<i class="bi bi-quote"></i></p>
        <img class="float-end" width="80" src="https://www.hittamaklare.se/assets/logo/logo-03d757d45dcdc4da535efd2a2a603ef25c88f0c205866e0cfd79419775c4f6ef.png"/>
                  </div>
                  <div class="col-md-4 px-3" v-if="reviews[index+1]">
                    <p><i class="bi bi-quote"></i>{{reviews[index+1].text}}<i class="bi bi-quote"></i></p>
        <img class="float-end" width="80" src="https://www.hittamaklare.se/assets/logo/logo-03d757d45dcdc4da535efd2a2a603ef25c88f0c205866e0cfd79419775c4f6ef.png"/>
                  </div>
                  <div class="col-md-4 px-3" v-if="reviews[index+2]">
                    <p><i class="bi bi-quote"></i>{{reviews[index+2].text}}<i class="bi bi-quote"></i></p>
        <img class="float-end" width="80" src="https://www.hittamaklare.se/assets/logo/logo-03d757d45dcdc4da535efd2a2a603ef25c88f0c205866e0cfd79419775c4f6ef.png"/>
                  </div>
                  <div class="col-md-4 px-3" v-if="reviews[index+3]">
                    <p><i class="bi bi-quote"></i>{{reviews[index+3].text}}<i class="bi bi-quote"></i></p>
        <img class="float-end" width="80" src="https://www.hittamaklare.se/assets/logo/logo-03d757d45dcdc4da535efd2a2a603ef25c88f0c205866e0cfd79419775c4f6ef.png"/>
                  </div>
              </div> 
            </div>
            <a class="carousel-control-prev bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            </a>
            <a class="carousel-control-next bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
            </a>
        </div>
    </div>
  </div>
    <div class="container pb-5" v-if="content.apiId != 'marbella'">
      <div class="row listing justify-content-center mb-3" v-if="isBroker">
          <div class="label-container col-md-2 col-6 px-0">
            <span class="broker-object-label">Mäklarens objekt</span>
          </div>
      </div>
      <!-- <div class="row listing justify-content-center" v-if="isBroker">
            <div :class="'button black col-md-2 col-6' + (status==forSale ? ' active':'')" v-on:click="status=forSale">Till salu</div>
            <div :class="'button black col-md-2 col-6' + (status==sold ? ' active':'')" v-on:click="status=sold">Sålda</div>
      </div> -->
      <div class="row justify-content-center" v-if="isBroker">
        <div class="col-md-10 row px-0">
          <div v-if="loading" class="col-12 position-relative py-5">
            <Circle color="#d09658"></Circle>
          </div>
          <template v-if="forSale==status" >
            <div v-for="listingitem in forSaleListings"  class="col-md-6 col-12 mt-0 px-1" :key="listingitem.id">
                <ListingItem :listingItem="listingitem" :content="content"/>
            </div>
          </template>
          <template v-if="sold==status" >
            <div v-for="(listingitem, index) in soldListings"  class="col-md-6 col-12 mt-0 px-1" :key="listingitem.id">
                <ListingItem :listingItem="listingitem" :content="content" v-if="index < 20"/>
            </div>
          </template>
          
        </div>
        <!-- <div class="col-5 text-center">
          <button v-if="chunk.length < listings.length" class="button black" v-on:click="getMoreItems()">SE FLER HEM</button>
        </div> -->
      </div>
      
    </div>
    <ContactForm :msg="statics['Kontakta mig']" v-if="broker != null" :broker="broker" :content="content"/>
    <Footer :footer="content.footer"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Search from '@/components/Search.vue'
import ListingItem from '@/components/ListingItem.vue'
import ContactForm from '@/components/ContactForm.vue'
import NavHidden from '@/components/NavHidden.vue'
import NavHiddenSideBar from '@/components/NavHiddenSideBar.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
const axios = require('axios');
import { useRoute } from 'vue-router'
// import {Circle} from 'vue-loading-spinner';
import Circle from 'vue-spinner/src/PulseLoader.vue'
//import { createPopper } from '@popperjs/core';
export default {
  name: 'BrokerDetail',
  components: {
    ListingItem,
    ContactForm,
    NavHiddenSideBar,NavHidden,Nav,Footer,Circle,Search
    //BrokerPuff,
    //BrokerBanner
},
props:{
    content:Object,
    title: String,
    description: String
    //slug:String
  },
  data: function(){
    return{
      app_url:process.env.VUE_APP_URL,
      isBroker:false,
      broker:null,
      listings:[],
      //description:null,
      statics:{},
      userId:null,
      soldListings:[],
      forSaleListings:[],
      chunk:[],
      rating:null,
      reviews:[],
      comming:"2",
      forSale:"3",
      sold:"4",
      soldReference:"10",
      status:"3",
      showSideBar:false,
      loading:true,
      showSearch:false
    }
  },
  created: function(){
    var statics = require('@/helpers/lang_'+this.content.lang);
    this.statics = statics.lang;
    this.getWpBroker();
    
  },methods: {
    getWpBroker(){
      //this.userId = this.$route.query.userId;
      const route = useRoute();
      this.userId = route.params.slug;
      var self = this;
      axios.post('https://easypress.se/wafast/graphql', {
          query: "{post(id:\""+this.userId+"\",idType:SLUG){ title,slug,content,medarbetareInformation{phone,email,title,vitecUser,headerImagePosition,profileImage{id,srcSet,sourceUrl},headerImage{id,srcSet,sourceUrl},teaserImage{id,srcSet,sourceUrl}},featuredImage{node{id,srcSet,sourceUrl}}}}"
        }).then(function(response){
            self.broker = response.data.data.post;
            if(self.broker.medarbetareInformation.vitecUser != null){
              self.isBroker = self.broker.medarbetareInformation.title.toLowerCase().indexOf('fastighetsmäklare') > -1;
              if(self.isBroker && self.content.apiId != "marbella"){
                self.getBrokerListings(self.broker.medarbetareInformation.vitecUser);
                if(self.content.apiId != "vasteras")self.getRating(self.broker.title);
              }
            }
        });
      /**
       * 
       * {employee(id:\"markus-olsson\",idType:SLUG){ title,slug,content,medarbetareInformation{phone,email,title,vitecUser,headerImage{id,sourceUrl},teaserImage{id,sourceUrl}},featuredImage{node{id,sourceUrl}}}}
       */
    },
    getRating(userName){
      //var userName = this.$route.query.userName;
      var self = this;
      const url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/brokers/get_rating?userName="+userName;
        axios.get(url)
        .then(function(response){
          //console.log(response.data);
          self.rating = response.data;
          //self.getReviews(self.rating.id);
        }).catch(function(error){
          console.log(error);
        });
    },
    getReviews(userId){
      //var userName = this.$route.query.userName;
      var self = this;
      const url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/brokers/get_reviews?userId="+userId;
        axios.get(url)
        .then(function(response){
          console.log(response.data);
          self.reviews = response.data.reviews;
          //self.rating = response.data;
        }).catch(function(error){
          console.log(error);
        });
    },
    getBrokerListings(userId){
      //var userName = this.$route.query.userName;
      var self = this;
      //const url = "http://localhost:3000/api/listings/getall";
      let url = "";
        url = process.env.VUE_APP_URL+"/api/sthlm/listings/getalldb?userId="+userId;
        axios.get(url)
        .then(function(response){
          //console.log(response.data);
          self.loading = false;
          for(let item of response.data){
              self.forSaleListings.push({
                id:item.estateId,
                areaName:item.areaName,
                livingSpace:item.livingSpace,
                mainImage:item.mainImage,
                streetAddress:item.streetAddress,
                price:item.price.startingPrice,
                status:item.assignment.status,
                type:item.subType == 'House' ? 'house' : 'housingCooperativese',
                rooms:item.rooms
              });
            }
            /*
          response.data.forEach(function(item){
            if(item.status.id == self.forSale || item.status.id == self.comming){
              self.forSaleListings.push(item);
            }
            else if(item.status.id == self.sold || item.status.id == self.soldReference){
              self.soldListings.push(item);
            }
          });
          */
          self.forSaleListings.sort(function (a, b) {
              if (a.price < b.price) {
                return 1;
              }
              if (a.price > b.price) {
                return -1;
              }
              // a must be equal to b
              return 0;
            });
          
          //self.listings = response.data;
          //self.chunk = self.listings.slice(0,30);
            //res.status(200).json(response.data);
        }).catch(function(error){
          console.log(error);
        });
    },
    NumberFormat(sum){
      return Number(sum).toLocaleString('sv');
    },
    getMoreItems(){
      //console.log(this.chunk.length);
      //console.log(this.listings.length);
      //this.chunk.push(...this.listings.slice(this.chunk.length, this.chunk.length+10));
    },
  }
}
</script>
<style lang="scss" scoped>
// @import "bootstrap/scss/functions";
// $carousel-control-color:#000;
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/mixins";
.header-image{
  object-position: center;
}
#recipeCarousel{
@media (max-width: 767px) {
  
    .carousel-inner .carousel-item>div {
        display: none;
    }

    .carousel-inner .carousel-item>div:first-child {
        display: block;
    }
  }


.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    display: flex;
}

/* medium and up screens */
@media (min-width: 768px) {

    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
        transform: translateX(25%);
    }

    .carousel-inner .carousel-item-start.active,
    .carousel-inner .carousel-item-prev {
        transform: translateX(-25%);
    }
  }

  .carousel-inner .carousel-item-end,
  .carousel-inner .carousel-item-start {
      transform: translateX(0);
  }
}
.broker-detail-page{
  background-color: #fff;
}
  .broker-description{
    word-wrap: break-word;
    text-align:left;
    p{
      margin-bottom:0;
    }
  }
.button.black{
  display:inline-block;
  margin-top:1rem;
  text-decoration: none;
  &.active{
    background-color:#000;
    color:#fff;
  }
}
.broker-links{
  text-decoration: none;
  color:#2c3e50;
}
.broker-container{
    height: 100%;
    @media (max-width: 700px) and (orientation: portrait) {
      height:500px;
    }
    position:relative;
    .img{
      position:absolute;
      bottom:0;
      height: 400px;
      width:300px;
      background-size: cover;
      background-color: #e6dfd9;
      background-blend-mode: multiply;
    }
    
  }
  .multiply{
    background-color: inherit;
    background-blend-mode: multiply;
  }
.object-description{
  text-align:left;
  a{
    color:#000;
  }
}
.broker-object-label{
  color:#fff;
  background:#000;
  padding:0.5rem 1rem 0.5rem 1rem;
  font-weight:600;
  display: block;
}
.btn-group button{
  background:none;
  color:#000;
}
.overlay{
  min-height: 500px;
}
.broker-intro{
  background-color: #e6dfd9;
  padding:1rem;
  padding-bottom:2.1rem;
}
.broker-info{
  list-style-type: none;
  padding:0;
  a.reco-link{
    color:#000;
  }
}

</style>

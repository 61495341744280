<template>
  <div class="selling">
    <Search v-if="showSearch" :content="content"></Search>
    <Nav @changeState="showSideBar=!showSideBar" :showSideMenu="true"/>
    <NavHiddenSideBar v-if="showSideBar" @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    <div id="nav-hidden" class="container-fluid navbar sticky-top" style="display:none;">
      <NavHidden @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    </div>
    <div class="carousel-container">
      <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="overlay d-flex align-items-center justify-content-center" style="background-color:#131625;">
                  <div v-if="data && data.featuredImage" class="banner-overlay"></div>
                  <img v-if="data && data.featuredImage" :src="data.featuredImage.node.sourceUrl" :srcset="data.featuredImage.node.srcSet" />
                  <!-- <img v-else :src="require(`@/assets/44.jpg`)" /> -->
                  <div class="teaser-container col-md-8 col-10">
                    <h3 class="h3-animation" v-if="data"><span>{{data.title}}</span></h3>
                    
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>
    <div class="container py-4">
      <div class="salja-grid row justify-content-center">
        <div class="col-12 col-md-8 pt-5 text-start">
          <h4 v-if="data">{{data.title}}</h4>
          <p v-if="data" v-html="data.content"></p>
        </div>
		</div>
    </div>
    
    <ContactForm msg="Skicka in din ansökan" :content="content"/>
    <Footer :footer="content.footer"/>
  </div>
</template>

<script>
import axios from 'axios'
import Search from '@/components/Search.vue'
//import BrokerPuff from '@/components/BrokerPuff.vue'
import ContactForm from '@/components/ContactForm.vue'
import NavHidden from '@/components/NavHidden.vue'
import NavHiddenSideBar from '@/components/NavHiddenSideBar.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import { useRoute } from 'vue-router';
//import { createPopper } from '@popperjs/core';
export default {
  name: 'Default',
  components: {
    ContactForm,
    NavHiddenSideBar,NavHidden,Nav,Footer,Search
  },
  props:{
    slug: String,
    content: Object,
    title: String,
    description: String
  },
  data: function(){
    return{
      app_url:process.env.VUE_APP_URL,
      showSideBar:false,
      showSearch:false,
      data:null,
      pageTitle:""
    }
  },
  watch: {
    '$route'(to, from) {
      // Called whenever the route changes
      // You can access the new route parameters with `to.params`
      this.fetchData(to.params.slug);
    }
  },
  methods:{
    fetchData(slug) {
      // Method to fetch data based on the current slug
    let self = this;
    
    
    axios.post('https://easypress.se/wafast/graphql', {
          query: "{post(id:\""+slug+"\",idType:SLUG){title,slug,content,excerpt,featuredImage{node{id,sourceUrl,srcSet}}}}"
        }).then(function(response){
            self.data = response.data.data.post;
            if(response.data.data.post == null){
              self.$router.push("/404");
            }
        }).catch(function(e){
          self.$router.push("/404");
        });
    }
  },
  mounted: function(){
    const route = useRoute();
    this.fetchData(route.params.slug);
  }
}
</script>
<style lang="scss" scoped>
.salja-grid h3 {
    font-size: 25px;
}
.salja-grid h3 span {
    color: #DC911B;
    font-size: 15px;
    display: inline-block;
    margin-right: 7px;
    position: relative;
}
</style>
<style lang="scss" scoped>
.overlay{
  min-height: 400px;
  @media (min-width: 768px) {
    min-height: 600px;
  }
  height: auto;
  background-color:#fff;
  img{
    object-position:bottom;
    object-fit:cover;
  }
}
.listing{
  display: flex;
  flex-wrap:wrap;
}
.broker-image{
  img{
    height: 400px;
  }
}

</style>

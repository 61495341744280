<template>
<div class="carousel-container">
    <div id="carouselExampleIndicators" class="carousel carousel-fade" data-bs-ride="carousel">
      <div class="carousel-inner" v-if="listing != null">
        <template v-for="(image,index) in listing.images">
          <div class="carousel-item" :class="index == 0 ? ' active':''" v-if="(image.imageId != null && 
          image.imageId != '' && 
          image.category != 'Hemnet' && 
          image.category != 'Planritningar' && 
          image.category != 'Planlösning')" :key="image.imageId">
            <div class="overlay d-flex align-items-center justify-content-center">
              <img :src="app_url+'/api/'+content.apiId+'/listings/get_img/?imageId='+image.imageId" />
              <!-- <img v-if="index == 0 || index == 1" :src="app_url+'/api/'+content.apiId+'/listings/get_img/?imageId='+image.imageId" />
              <img v-else :data-lazy-load-src="app_url+'/api/'+content.apiId+'/listings/get_img/?imageId='+image.imageId" /> -->
            </div>
          </div>
        </template>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .overlay{
    height: 300px;
    img{
      // object-fit:contain;
      object-position: top;
    }
  }
}


</style>
<script>
import $ from 'jquery';
export default {
  name: 'TopSlider',
  props: {
    listing:Object,
    content:Object
  },
  data: function(){
    return{
      app_url:process.env.VUE_APP_URL
    }
  },
  created:function(){
    var cHeight = 0;
    var myCarousel = document.getElementById('carouselExampleIndicators');
if(myCarousel){
    myCarousel.addEventListener('slide.bs.carousel', function (e) {
        var $nextImage = null;
        var $siblingImage = null;

        var $activeItem = $('.active.carousel-item', this);

        if (e.direction == 'left'){
            $nextImage = $activeItem.next('.carousel-item').find('img');
            $siblingImage = $activeItem.next('.carousel-item').next('.carousel-item').find('img');
        } else {
        if ($activeItem.index() == 0){
                  $nextImage = $('img:last', $activeItem.parent());
              } else {
                  $nextImage = $activeItem.prev('.carousel-item').find('img');
                  $siblingImage = $activeItem.prev('.carousel-item').prev('.carousel-item').find('img');
              }
        }

        // prevents the slide decrease in height
        if (cHeight == 0) {
           cHeight = $(this).height();
           $activeItem.next('.carousel-item').height(cHeight);
           $activeItem.next('.carousel-item').next('.carousel-item').height(cHeight);
        }

        // prevents the loaded image if it is already loaded
        var src = $nextImage.data('lazy-load-src');
        
        
        if (typeof src !== "undefined" && src != "") {
           $nextImage.attr('src', src)
           $nextImage.data('lazy-load-src', '');
        }
        if($siblingImage != null){
          var siblingSrc = $siblingImage.data('lazy-load-src');
          if (typeof siblingSrc !== "undefined" && siblingSrc != "") {
            $siblingImage.attr('src', siblingSrc)
            $siblingImage.data('lazy-load-src', '');
          }
        }
        
  });
}
  },
   methods: {
    // changeState() {
    //   this.$emit('changeState')
    // }
    }
}
</script>

<template>
  <div>
    <nav class="nav-menu d-flex justify-content-start">
      <!-- <router-link v-for="item in menu.items" :to="item.url" :key="item.url" class="col-md-1 col-12 desktop">{{item.text}}</router-link> -->
      <!-- <router-link to="/" class="col-md-2 col-12"><img  class="logo" :src="require(`@/assets/logo/WA-White-Emblem.svg`)"></router-link> -->
      <router-link to="/" class="nav-link"><img  class="logo" :src="require(`@/assets/logo/WA-Logo-White-Long.svg`)"></router-link>
      <!-- <router-link v-for="item in menu.items2" :to="item.url" :key="item.url" class="col-md-1 col-12 desktop">{{item.text}}</router-link> -->
      <!-- <template v-if="menu.external">
        <a v-for="item in menu.external" :key="item.url" :href="item.url" class="col-md-1 col-12 desktop" target="_blank">{{item.text}}</a>
      </template> -->
    </nav>
    <i class="menu bi bi-list" v-on:click="changeState()"></i>
  </div>
</template>

<script>
export default {
  name: 'NavHidden',
  props: {
    menu:Object
  },
  methods: {
    changeState() {
      this.$emit('changeState')
    }
  },
  created: function(){
  window.addEventListener('scroll', () => {
      if(window.scrollY > 50){
        let nav = document.getElementById("nav-hidden");
        if(nav != null) nav.style.display = 'block';
      } 
      else{
        let nav = document.getElementById("nav-hidden");
        if(nav != null) nav.style.display = 'none'; 
      } 
    });
  },unmounted:function(){
    window.removeEventListener('scroll', () => {
      
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/globals/styles/colorPalette";
  @keyframes fadeIn {
  from {opacity: 0%;}
  to {opacity: 100%;}
}
.nav-menu.nav-hidden a,.nav-menu.nav-hidden span {
  z-index:1000;
  // color:#d09658;
  color:#fff;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

.nav-menu{
  padding: 15px 30px 15px 30px;
  // padding-top: 5px;
  // background-color: #0a101b;
  background-color:$main-dark-beige;
  display: block;
  border-bottom: 2px solid #e4e4e4;
  animation-name: fadeIn;
  animation-duration: 2s;
  
}
.menu{
    // color:#d09658;
    color:#fff;
    font-size:30pt;
    position: absolute;
    // color: #fff;
    z-index:1000;
    top: 0;
    right: 10px;
  }
.nav-menu img{
  width:$logo;
}
.nav-menu.nav-hidden a svg.logo{
    width:$logo;
}
.nav-menu.nav-hidden{
    padding:1rem;
}
</style>

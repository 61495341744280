let lang = {
    "Kontakta oss":"Kontakta oss",
    "Bli kontaktad för en kostnadsfri värdering":"Bli kontaktad för en kostnadsfri värdering",
    "Bostäder till salu":"Bostäder till salu",
    "Bostäder att hyra":"Bostäder att hyra",
    "Hittar du något du gillar?":"Hittar du något du gillar?",
    "Här ser du några av de senast inkomna bostäderna hos oss. Kanske är någon av dem ert nästa hem?":"Här ser du några av de senast inkomna bostäderna hos oss. Kanske är någon av dem ert nästa hem?",
    "Här är våra utvalda exklusiva hem att hyra":"Här är våra utvalda exklusiva hem att hyra",
    "Se fler hem":"Se fler hem",
    "Ska du sälja din bostad?":"Ska du sälja din bostad?",
    "Förnamn":"Förnamn",
    "Efternamn":"Efternamn",
    "Telefonnummer":"Telefonnummer",
    "E-post":"Email",
    "Meddelande":"Meddelande",
    "Skicka":"Skicka",
    "Jag godkänner Wikingskiöld / Aspelins behandling av mina personuppgifter." : "Jag godkänner Wikingskiöld / Aspelins behandling av mina personuppgifter.",
    "integritetspolicy":"integritetspolicy",
    "Meddelande har skickats!":"Meddelande har skickats!",
    "Något gick fel!":"Något gick fel!",
    "Inga fastigheter":"Inga fastigheter",
    "Läs mer":"Läs mer",
    "Sök":"Sök",
    "Alla områden":"Alla områden",
    "Område":"Område",
    "Sortering":"Sortering",
    "Högst pris":"Högst pris",
    "Lägst pris":"Lägst pris",
    "Se fler bilder":"Se fler bilder",
    "Intresseanmälan":"Intresseanmälan",
    "Karta":"Karta",
    "Pris":"Pris",
    "Hyra":"Hyra",
    "På begäran":"På begäran",
    "Rum":"Rum",
    "Badrum":"Badrum",
    "Sovrum":"Sovrum",
    "Boarea":"Boarea",
    "Vårt":"Vårt",
    "Team":"Team",
    "Kontakta mig":"Kontakta mig",
    "Dokument":"Dokument",
    "Nyproduktion":"Nyproduktion",
    "kvm":"kvm",
    "Terass":"Terass",
    "Sök på område eller adress":"Sök på område eller adress",
    "Beskrivning":"Beskrivning",
    "Bilder":"Bilder",
    "Adress, Ort, Mäklare":"Adress, Ort, Mäklare"
};
exports.lang = lang;
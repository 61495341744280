<template>
    <router-view/>
</template>
<script>
export default {
  name: 'App',
  data: function(){
    return{
      app_url:process.env.VUE_APP_URL,
      showSideBar:false
    }
  },
  created: function(){
    
  },
  methods: {
    getActiveClass(index){
      return index == 0 ? 'active' : '';
    },
    changeState() {
      this.showSideBar = !this.showSideBar;
    }
  }
}
</script>

<style lang="scss">
//@import "@fontsource/poppins";
//@import "@fontsource/bodoni-moda";
@import '@/globals/main.scss';


@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~bootstrap/dist/css/bootstrap.css';

//@import '~@fontsource/roboto';
// @import '~@fontsource/spartan';

</style>

<template>
  <div class="selling">
    <Search v-if="showSearch" :content="content"></Search>
    <!-- <Nav @changeState="showSideBar=!showSideBar" :showSideMenu="true"/> -->
    <NavHiddenSideBar v-if="showSideBar" @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    <div class="container-fluid navbar sticky-top py-0" style="display:block;">
      <NavHidden @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    </div>
    <div class="container-fluid">
      <div class="row justify-content-center py-4" :style="'background-image:url('+require(`@/assets/44.jpg`)+');background-size:cover;'">
        <div class="col-md-4">
          <ContactForm :content="content" msg="Hur kan vi hjälpa till?"/>
        </div>
    </div>
  </div>
    <Footer :footer="content.footer"/>
  </div>
</template>

<script>
//import BrokerPuff from '@/components/BrokerPuff.vue'
import Search from '@/components/Search.vue'
import ContactForm from '@/components/ContactFormNew.vue'
import NavHidden from '@/components/NavHidden.vue'
import NavHiddenSideBar from '@/components/NavHiddenSideBar.vue'
// import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
//import { createPopper } from '@popperjs/core';
export default {
  name: 'AboutUs',
  components: {
    ContactForm,
    NavHiddenSideBar,NavHidden,
    //Nav,
    Footer,Search
  },
  props:{
    content:Object,
    title: String,
    description: String
  },
  data: function(){
    return{
      app_url:process.env.VUE_APP_URL,
      brokers:[],
      showSideBar:false,
      showSearch:false
    }
  },
  created: function(){
    //this.getBrokers();
  },methods: {
    // getBrokers(){
    //   var self = this;
    //   const url = process.env.VUE_APP_URL+"/api/brokers/get_custom_brokers";
    //     axios.get(url)
    //     .then(function(response){
    //       self.brokers = response.data;
    //       self.brokers.sort(function (a, b) {
    //         if (a.order > b.order) {
    //           return 1;
    //         }
    //         if (a.order < b.order) {
    //           return -1;
    //         }
    //         // a must be equal to b
    //         return 0;
    //       });
    //     });
    // }
  }
}
</script>
<style lang="scss">
.office-puff{
  position:relative;
  // & > div{
  //   position: absolute;
  // }
  .office-puff-content{
    z-index:600;
    position: inherit;
    a{
      color:#fff;
    }
  }
  opacity:0.8;
  color:#fff;
  background-size: cover;
  background-repeat: none;
  background-image: url('https://www.esny.se/app/uploads/2019/08/Kontakt-Stockholm-632x1024.jpg');
}
.salja-grid h3 {
    font-size: 25px;
    // display: flex;
    //  font-family: 'Adamina', serif;
}
.salja-grid h3 span {
    color: #DC911B;
    font-size: 15px;
    display: inline-block;
    margin-right: 7px;
    position: relative;
    // top: 14px;
    // font-family: 'Adamina', serif;
}
</style>
<style lang="scss" scoped>
.item{
  position:relative;
}
  .item{
        &:before{
      content: "";
        display: block;
        position: absolute;
        top: -55px;
        left: 50%;
        height: 2.5625rem;
        border-right: 1px solid #0a0a0a;
        transform: translateX(-50%);
    }
  }


.listing{
  display: flex;
  flex-wrap:wrap;
}
.broker-image{
  img{
    height: 400px;
  }
}

</style>

<template>
<div v-if="show" class="modal fade show" :id="id" tabindex="-1" style="display:block;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content contact-form">
      <div class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
        <button type="button" class="btn-close" aria-label="Close" v-on:click="this.$parent.showModal=!this.$parent.showModal"></button>
      </div>
      <div class="modal-body">
        <!-- <div class="container-fluid"> -->
          <div class="row">
            <div class="col-md-6 col-12 mb-3">
              <input type="text" class="form-control mb-2" v-model="firstname" placeholder="Förnamn"/>
              <input type="phone" class="form-control" v-model="phonenumber" placeholder="Telefonnummer"/>
              
              <input v-if="hasAddress" type="text" class="form-control" v-model="address" placeholder="Adress"/>
            </div>
            <div class="col-md-6 col-12 mb-3">
              <input type="text" class="form-control" v-model="lastname" placeholder="Efternamn"/>
              
              <input type="email" class="form-control my-2" v-model="email" placeholder="E-post"/>
              <!-- <textarea class="form-control h-100" v-model="message" rows="7" placeholder="Meddelande"></textarea> -->
            </div>
            <div class="col-12 justify-content-left">
              {{messageSent}}
            </div>
          </div>
        <!-- </div> -->
      </div>
      <div class="modal-footer">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" v-model="policyApprove" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault" style="padding-left:10px;">
            Jag godkänner Wikingskiöld / Aspelins behandling av mina personuppgifter.
          </label>
        </div>
        <button :disabled="(!policyApprove || phonenumber == '' || email == '')" :class="(!policyApprove || phonenumber == '' || email == '') ? 'disabled':''" class="button black" v-on:click="submitForm">SKICKA</button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import axios from 'axios';
//import ContactForm from '@/components/ContactForm.vue';
export default {
  name: 'BookingModal',
  props:{
    id: String,
    title: String,
    body: String,
    show: Boolean,
    content:Object,
    estateId:String,
    broker:Object,
    hasAddress:Boolean
  },
  components:{
    //ContactForm
  },
  data: function(){
    return{
      firstname:'',
      lastname:'',
      phonenumber:'',
      email:'',
      message:'',
      messageSent:'',
      policyApprove:false
    }
  },
   methods: {
    submitForm(){
      var self = this;
      

      if(self.estateId != null && self.estateId != ''){
        let url = process.env.VUE_APP_URL+"/api/contact/send_interest_vitec?estateId="+self.estateId;
        
        axios.post(url, {
          firstname:self.firstname,
          lastname:self.lastname,
          phonenumber:self.phonenumber,
          email:self.email
        })
        .then(function (response) {
          console.log(response);
          
          self.messageSent = 'Bokning registrerat!';
          setTimeout(function(){
            self.firstname = '';
            self.lastname = '';
            self.phonenumber = '';
            self.email = '';
            self.messageSent = '';
            self.$parent.showModal=!self.$parent.showModal;
          }, 2000);
        })
        .catch(function (error) {
          console.log(error);
          self.messageSent = 'Något gick fel, försök igen.';
          setTimeout(function(){
            self.firstname = '';
            self.lastname = '';
            self.phonenumber = '';
            self.email = '';
            self.messageSent = '';
            self.$parent.showModal=!self.$parent.showModal;
          }, 2000);

          
        });
      }
    }
    // changeState() {
    //   this.$emit('changeState')
    // }
    }
}
</script>
<style lang="scss" scoped>
.modal-content{
  background-color:#9F9689;
  color:#fff;
}
.modal-header, .modal-footer{
  border:none;
}
.contact-form{
  a{
    color:#fff;
  }
  text-align:left;
  color:#fff;
  input,textarea{
    background-color: #aca499;
    padding:0.7rem;
    border:none;
    color:#fff;
    &::placeholder{
      color:#fff;
      opacity:0.5
    }
  }
}
.container-fluid.no-padding{
  padding:0;
}
.two-column-banner.banner{
  padding:0;
}
.button.black{
  float:right;
  background:#2a2828;
  color:#fff;
  padding:1rem;
  padding-left:3rem;
  padding-right:3rem;
  &.disabled{
    opacity: 0.5;
  }
  /* width:150px; */
}
.banner .banner-text{
  z-index:50;
  text-align:left;
  // padding:2rem;
  // @media (min-width: 700px) and (orientation: landscape) {
  //   padding:5rem; 
  // }
}
</style>

<template>
  <div>
    <TopSlider :listing="listing" :content="content"></TopSlider>
    <div class="container">
      <div class="row justify-content-center py-5 mt-5" v-if="(listing.advertiseOn.showAsComming && listing.assignment.status.id == '2')">
        <div class="col-12 text-center">
          <h2>Kommande</h2>
        </div>
      </div>
      <div class="row justify-content-center object-description pb-4">
        <div class="col-md-8 col-12 mt-5">
          <h1>{{listing.baseInformation.objectAddress.streetAddress}}</h1>
          <p v-if="listing.baseInformation.objectAddress.area">{{listing.baseInformation.objectAddress.municipality +' / '+  listing.baseInformation.objectAddress.area+', '+listing.baseInformation.objectAddress.county}} län</p>
          <p v-else>{{listing.baseInformation.objectAddress.municipality +' / '+  listing.baseInformation.objectAddress.county}} län</p>
          <div class="row gx-2 gy-2">
            <div class="col-md-4 col-6" v-if="listing.building && listing.building.buildingType">
                <span>Bostadstyp:</span><br> {{listing.building.buildingType}}
            </div>
            <div class="col-md-4 col-6">
              <span>Antal rum:</span><br> {{listing.houseInterior == null ? listing.interior.numberOfRooms : listing.houseInterior.numberOfRooms}} rum och kök
            </div>
            <div class="col-md-4 col-6">
              <span>Boarea:</span><br> {{listing.baseInformation.livingSpace}} kvm
            </div>
            <div class="col-md-4 col-6" v-if="listing.building && listing.building.buildingYear">
              <span>Byggnadsår:</span><br> {{listing.building.buildingYear}}
            </div>
            <div class="col-md-4 col-6" v-if="listing.floorAndElevator != null">
                <span>Hiss:</span><br> {{listing.floorAndElevator.elevator == 'Yes' ? 'Ja' : 'Nej'}}
            </div>
            <div class="col-md-4 col-6" v-if="(listing.floorAndElevator != null && listing.floorAndElevator.totalNumberFloors)"><span>Våning:</span><br> {{listing.floorAndElevator.floor}} av {{listing.floorAndElevator.totalNumberFloors}}</div>
            <div class="col-md-4 col-6" v-else-if="(listing.floorAndElevator != null && listing.floorAndElevator.floor)"><span>Våning:</span><br> {{listing.floorAndElevator.floor}}</div>
            <div class="col-md-4 col-6" v-if="listing.plot != null"><span>Tomtarea:</span><br> {{listing.plot.area}} kvm, {{listing.baseInformation.tenure}}</div>
            <div class="col-md-4 col-6" v-if="listing.baseInformation.otherSpace != null && listing.baseInformation.otherSpace != 0"><span>Biarea:</span><br> {{listing.baseInformation.otherSpace}} kvm</div>
            <div class="col-md-4 col-6" v-if="listing.baseInformation.monthlyFee != null">
              <span>Månadsavgift:</span><br> {{listing.baseInformation.monthlyFee}} kr
              <p>{{listing.baseInformation.commentary}}</p>
            </div>
          </div>
          <div class="card card-body w-100 mt-4" v-if="listing.baseInformation && listing.baseInformation.objectAddress && listing.baseInformation.objectAddress.coordinate">
            <iframe width="100%" height="250" frameborder="0" style="border:0" :src="'https://www.google.com/maps/embed/v1/place?q='+listing.baseInformation.objectAddress.coordinate.latitud+','+listing.baseInformation.objectAddress.coordinate.longitud+'&amp;key=AIzaSyDYBhfDP-Flbm1vMbgoYtDn1jf5QQiq4UU'"></iframe>
          </div>
        </div>
        <div class="col-md-4 col-12 my-5 mb-md-2">
          <PriceBox :listing="listing" :content="content"></PriceBox>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row information text-start px-md-5 px-2 pb-5">
        <div class="col-12 px-md-5 mt-5">
          <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="pills-description-tab" data-bs-toggle="pill" data-bs-target="#pills-description" type="button" role="tab" aria-controls="pills-description" aria-selected="false">Beskrivning</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-images-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Bilder</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-fact" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Fakta</button>
            </li>
            <li class="nav-item" role="presentation" v-if="listing.association">
              <button class="nav-link" id="pills-association-tab" data-bs-toggle="pill" data-bs-target="#pills-association" type="button" role="tab" aria-controls="pills-association" aria-selected="false">Föreningen</button>
            </li>
            <li class="nav-item" role="presentation" v-if="listing.advertiseOn.documents != null && listing.advertiseOn.documents.length > 0">
              <button class="nav-link" id="pills-document-tab" data-bs-toggle="pill" data-bs-target="#pills-document" type="button" role="tab" aria-controls="pills-document" aria-selected="false">Dokument</button>
            </li>
            <li class="nav-item" role="presentation" v-if="hasPlanritning(listing.images)">
              <button class="nav-link" id="pills-planritning-tab" data-bs-toggle="pill" data-bs-target="#pills-planritning" type="button" role="tab" aria-controls="pills-planritning" aria-selected="false">Planritning</button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-description" role="tabpanel" aria-labelledby="pills-description-tab">
              <div class="row pt-3 pb-3">
                <div class="col-12 col-md-6">
                  <p class="preline" v-html="listing.description.shortSellingDescription"></p>
                  <p class="preline" v-html="listing.description.longSellingDescription"></p>
                </div>
                <div class="col-12 col-md-6" v-if="listing.images.length > 1">
                  <img :src="app_url+'/api/'+content.apiId+'/listings/get_img/?imageId='+listing.images[1].imageId+'&width=800'" class="view-box w-100" :alt="listing.images[0].category ? listing.images[0].category : listing.images[0].text"  loading="lazy">
                  <!-- <div class="card card-body w-100 mt-4">
                    <iframe width="100%" height="600" frameborder="0" style="border:0" :src="'https://www.google.com/maps/embed/v1/place?q='+listing.baseInformation.objectAddress.coordinate.latitud+','+listing.baseInformation.objectAddress.coordinate.longitud+'&amp;key=AIzaSyDYBhfDP-Flbm1vMbgoYtDn1jf5QQiq4UU'"></iframe>
                  </div> -->
                </div>
              </div>
              <p class="collapsible-link" v-if="listing.houseInterior != null && listing.houseInterior.generealDescription != ''">
                <a class="" data-bs-toggle="collapse" href="#read-more" role="button" aria-expanded="false" aria-controls="collapseExample">
                  LÄS MER
                </a>
              </p>
              <div class="collapse" id="read-more" v-if="listing.houseInterior!= null && listing.houseInterior.generealDescription != ''">
                <div class="card card-body">
                  <p class="preline" v-html="listing.houseInterior.generealDescription"></p>
                </div>
              </div>
              <p class="collapsible-link" v-if="listing.interior != null && listing.interior.generealDescription != ''">
                <a class="" data-bs-toggle="collapse" href="#read-more" role="button" aria-expanded="false" aria-controls="collapseExample">
                  LÄS MER
                </a>
              </p>
              <div class="collapse row" id="read-more" v-if="listing.interior != null && listing.interior.generealDescription != ''">
                <div class="col-12 col-md-6">
                  <p class="preline" v-html="listing.interior.generealDescription"></p>
                </div>
                <div class="col-12 col-md-6" v-if="listing.images.length > 2">
                  <img :src="app_url+'/api/'+content.apiId+'/listings/get_img/?imageId='+listing.images[2].imageId+'&width=800'" class="view-box w-100" :alt="listing.images[2].category ? listing.images[2].category : listing.images[2].text"  loading="lazy">
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-images-tab">
              <div class="row gx-2 gy-2">
                <photo-provider>
                <template v-for="(image) in listing.images" >
                  <photo-consumer
                  v-if="image.imageId != null && image.imageId != '' && 
                  (image.category != 'Planlösning' || image.category != 'Planritningar')"
                  :key="image.imageId"
                  class="bg-image col-md-4 col-12"
                  style="height:300px;"

                  :src="app_url+'/api/'+content.apiId+'/listings/get_img/?imageId='+image.imageId">
                    <img :src="app_url+'/api/'+content.apiId+'/listings/get_img/?imageId='+image.imageId+'&width=1200'" class="view-box w-100 h-100" style="object-fit:cover;" :alt="image.text" loading="lazy">
                  </photo-consumer>
                </template>
              </photo-provider>
            </div>
            </div>
            <div class="tab-pane fade" id="pills-fact" role="tabpanel" aria-labelledby="pills-fact-tab">
              <GeneralInfo :listing="listing"></GeneralInfo>
              <div class="row pt-3">
                <div class="col-md-6" v-if="listing.surrounding && listing.surrounding.generalAboutArea">
                  <span class="text-decoration-underline">Allmänt om området</span>
                  <p v-html="listing.surrounding.generalAboutArea"></p>
                </div>
              </div>
              <div class="row pt-3 justify-content-center" v-if="listing.images.length > 2">
                <div class="col-12 col-md-6">
                  <img :src="app_url+'/api/'+content.apiId+'/listings/get_img/?imageId='+listing.images[2].imageId+'&width=800'" class="view-box w-100" :alt="listing.images[2].category ? listing.images[2].category : listing.images[2].text"  loading="lazy">
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-association" role="tabpanel" aria-labelledby="pills-association-tab" v-if="listing.association != null">
              <div class="row">
                <div class="col-12 col-md-6" v-if="listing.association.generalAboutAssociation">
                  <p>Allmänt om föreningen</p>
                  <p class="preline" v-html="listing.association.generalAboutAssociation"></p>
                </div>
                <div class="col-12 col-md-6" v-if="listing.association.generalAboutAssociation && listing.images.length > 3">
                  <img :src="app_url+'/api/'+content.apiId+'/listings/get_img/?imageId='+listing.images[3].imageId+'&width=800'" class="view-box w-100" :alt="listing.images[3].category ? listing.images[3].category : listing.images[3].text"  loading="lazy">
                </div>
                <div class="card card-body document-links" v-if="listing.association != null && listing.association.documents && listing.association.documents.length > 0">
                  <span class="text-decoration-underline">Dokument:</span><br>
                  <a target="_blank" :href="app_url+'/api/'+content.apiId+'/documents/get_doc/?docId='+document.id" 
                    v-for="document in listing.association.documents" 
                    :key="document.id">
                    <i class="bi bi-file-pdf"></i> {{document.name}}
                  </a>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-document" role="tabpanel" aria-labelledby="pills-document-tab" v-if="listing.advertiseOn.documents != null && listing.advertiseOn.documents.length > 0">
              <div class="card card-body document-links">
                <a target="_blank" :href="app_url+'/api/'+content.apiId+'/documents/get_doc/?docId='+document.id" 
                v-for="document in listing.advertiseOn.documents" 
                :key="document.id">
                <i class="bi bi-file-pdf"></i> {{document.name}}
                </a>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-planritning" role="tabpanel" aria-labelledby="pills-planritning-tab" v-if="hasPlanritning(listing.images)">
              <photo-provider>
                  <template v-for="(image) in listing.images" >
                  <photo-consumer
                  :key="image.imageId"
                  v-if="image.imageId != null && (image.category == 'Planlösning' || image.category == 'Planritningar')"
                  class="bg-image px-2 pb-2 mt-2 col-md-4 col-12"
                  style="height: 100%;" 
                  :src="app_url+'/api/'+content.apiId+'/listings/get_img/?imageId='+image.imageId+'&width=1200'">
                    <img :src="app_url+'/api/'+content.apiId+'/listings/get_img/?imageId='+image.imageId+'&width=800'" class="view-box w-100" :alt="image.category ? image.category : image.text"  loading="lazy">
                  </photo-consumer>
                </template>
              </photo-provider>
            </div>
          </div>
        </div>
      </div>
      <div class="row information px-md-5 pb-5 justify-content-center">
        <div class="col-lg-6 mt-3" v-if="responsibleBroker != null && responsibleBroker.medarbetareInformation != null">
          <BrokerPuffBlend :broker="responsibleBroker" :content="content" theme="dark"/>
        </div>
        <div class="col-lg-6 mt-3" v-if="additionalContact != null && additionalContact.medarbetareInformation != null">
          <BrokerPuffBlend :broker="additionalContact" :content="content" theme="dark"/>
        </div>
      </div>
    </div>
    <ContactForm msg="Intresseanmälan" :estateId="listing.estateId" :broker="responsibleBroker" v-if="responsibleBroker != null" :content="content"/>
  </div>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'
import BrokerPuffBlend from '@/components/BrokerPuffBlend.vue'
import TopSlider from '@/components/TopSlider.vue'
import GeneralInfo from '@/components/GeneralInfoNew.vue'

import PriceBox from '@/components/PriceBox.vue'
//import ListingImage from '@/components/ListingImage.vue'
const axios = require('axios');
export default {
  name: 'PropertyDetailInfo',
  components: {
    TopSlider,
    BrokerPuffBlend,
    ContactForm,
    PriceBox,
    GeneralInfo
  },
  props: {
    content:Object,
    msg: String,
    listing:Object
  },
  data: function(){
    return{
      app_url:process.env.VUE_APP_URL,
      numberOfRooms:0,
      //numberOfRooms: this.listing.interior.numberOfRooms ? this.listing.interior.numberOfRooms : this.listing.houseInterior.numberOfRooms,
      showAllPictures : false,
      showAllBids: false,
      responsibleBroker:null,
      additionalContact:null,
      gallery:[],
      forSale:"3",
      sold:"4",
      soldReference:"10",
      comming:"2",
    }
  },
  created: function(){
    //this.gallery = this.listing.images.slice(0,6);
    this.gallery = this.listing.images;
    this.getBrokers();

  },
  methods: {
    hasPlanritning(images){
      for(let image of images){
        if(image.category.indexOf('Planlösning') > -1 || image.category.indexOf('Planritningar') > -1) return true
      }
      return false;
    },
    getMoreItems(){
      this.gallery.push(...this.listing.images.slice(this.gallery.length, this.gallery.length+12));
    },
    formatDate(timestamp) {
      return new Date(timestamp).toLocaleDateString('sv-SE');
    },
    NumberFormat(sum){
      return Number(sum).toLocaleString('sv');
    },
    async getBrokers(){
      this.responsibleBroker = await this.getBroker(this.listing.assignment.responsibleBroker);
    if(this.listing.assignment.additionalContact){
      this.additionalContact = await this.getBroker(this.listing.assignment.additionalContact);
    } 
    },
    async getBroker(userId){
  var self = this;
  try {
    let response = await axios.post('https://easypress.se/wafast/graphql', {
      query: `{
        posts(first: 50, where: { categoryName: "medarbetare" }) {
          nodes {
            title,
            slug,
            content,
            medarbetareInformation {
              phone,
              email,
              title,
              vitecUser,
              profileImage {
                id,
                srcSet,
                sourceUrl
              },
              headerImage {
                id,
                srcSet,
                sourceUrl
              },
              teaserImage {
                id,
                srcSet,
                sourceUrl
              }
            },
            featuredImage {
              node {
                id,
                sourceUrl
              }
            }
          }
        }
      }`
    });

    let matchedBroker = null;
    for (let broker of response.data.data.posts.nodes) {
      if (broker.medarbetareInformation.vitecUser == userId) {
        matchedBroker = broker;
      }
    }
    return matchedBroker;
  } catch (error) {
    console.error('Failed to fetch broker:', error);
    // Handle errors or set error state here
  }
}

  }
}
</script>

<style scoped lang="scss">
.object-description{
  span{
    font-weight: bold;
  }
}
.preline{
  white-space: pre-line;
}
.information{
  background-color:#0a101b;
  a, .nav-link{
    color:#ccc8bd;
  }
  

  .nav-link.active{
    color:#fff;
    background-color: #ccc8bd;
  }
  color:#ccc8bd;
  .button.cream{
    border-color: #ccc8bd;
    color:#ccc8bd;
  }
}
.document-links{
  a{
    // color:#000;
    i{
      color:#d14;
    }
  }
  

}

h1{
  font-size:2rem;
}
.PhotoConsumer{
  height: 300px;
  overflow:hidden;
//   img{
//   transition: transform 0.5s; 
//   -webkit-transition: -webkit-transform 0.5s;
//   &:hover{
//     transform: scale(1.1); -webkit-transform: scale(1.1);
//   }
// }
}
</style>
<template>
  <div class="banner-text px-4 py-4">
    <form class="contact-form row text-center justify-content-center">
      <h2 class="text-start" v-if="msg != null">{{msg}}</h2>
      <h2 class="text-start" v-else>{{statics['Ska du sälja din bostad?']}}</h2>
      <p class="text-start" v-if="msg == null">{{statics['Kontakta oss']}}!</p>
      <div class="col-12 my-3">
        <input type="text" class="form-control mb-2" v-model="firstname" placeholder="Namn"/>
        <input type="phone" class="form-control my-2" v-model="phonenumber" :placeholder="statics['Telefonnummer']"/>
        <input type="email" class="form-control my-2" v-model="email" placeholder="E-post"/>
        <input type="text" class="form-control my-2" v-model="address" placeholder="Adress"/>
        <input type="text" class="form-control my-2" v-model="city" placeholder="Stad"/>
        <input type="text" class="form-control my-2" v-model="message" placeholder="Meddelande"/>
      </div>
      <div class="col-12">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" v-model="policyApprove" id="flexCheckDefault">
          <label class="form-check-label text-start mx-2" for="flexCheckDefault">
            Jag godkänner Wikingskiöld / Aspelin behandling av mina personuppgifter. Se vår <router-link to="/personuppgifter">Integritetspolicy</router-link>
          </label>
        </div>
      </div>
      <div class="col-12 justify-content-right">
      <button :disabled="(!policyApprove || phonenumber == '' || email == '')" :class="(!policyApprove || phonenumber == '' || email == '') ? 'disabled':''" class="button black text-uppercase" v-on:click="submitform">{{statics['Skicka']}}</button>
      {{messageSent}}
      </div>
    </form>
    
  </div>
</template>

<script>
//var statics = require('@/helpers/lang_en');
const axios = require('axios');
export default {
  name: 'ContactForm',
  props: {
    msg: null,
    estateId: String,
    broker: Object,
    content: Object,
    hasAddress: Boolean,
    showTeaser: Boolean
  },
  created:function(){
    var statics = require('@/helpers/lang_'+this.content.lang);
    this.statics = statics.lang;
  },
  data: function(){
    return{
      statics:{},
      firstname:'',
      lastname:'',
      phonenumber:'',
      address:'',
      city:'',
      email:'',
      message:'',
      messageSent:'',
      policyApprove:false,
      teaser:true
    }
  },
  methods:{
    disableTeaser(){
      this.teaser = false;
      document.cookie = "unik-teaser=1; expires=Thu, 18 Dec 2100 12:00:00 UTC";
    },
    submitform(e){
      e.preventDefault();
      var self = this;
      var _firstName = self.firstname;
      var _lastName = self.lastname;
      var _email = self.email;
      var _phoneNumber = self.phonenumber;
      let url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/contact/send_mail";
      let email = "info@wafast.se";
      axios.post(url, {
        firstname:self.firstname,
        lastname:self.lastname,
        phonenumber:self.phonenumber,
        message:self.message,
        email: self.email,
        city: self.city,
        address: self.address,
        url:self.$router.currentRoute.value.fullPath,
        broker: email,
        estateId: self.estateId
      })
      .then(function (response) {
        console.log(response.data);
        self.firstname = '';
        self.lastname = '';
        self.phonenumber = '';
        self.message = '';
        self.email = '';
        self.messageSent = self.statics['Meddelande har skickats!'];
      })
      .catch(function (error) {
        self.messageSent = self.statics['Något gick fel!'];
        console.log(error);
      });
      if(self.estateId != null && self.estateId != ''){
        url = process.env.VUE_APP_URL+"/api/contact/send_interest_vitec?estateId="+self.estateId;
        if(self.content.apiId == 'umea'){
          url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/contact/send_interest_vitec?estateId="+self.estateId;
        }
        axios.post(url, {
          firstname:_firstName,
          lastname:_lastName,
          phonenumber:_phoneNumber,
          email:_email
        })
        .then(function (response) {
          console.log(response);
          _firstName = '';
          _lastName = '';
          _phoneNumber = '';
          _email = '';
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.form-check-label{
  font-size:10pt;
}
.banner-text{
  background-color: #0a101b;
  opacity: 0.8;
}
.contact-teaser{
  z-index:400;
  bottom:0;
  
  width:100%;
  .contact-content{
    @media (max-width: 767.98px) {
      width:100% !important;
    }
    .btn-close{
      right:10px;
    }
    .title{
      font-size:20px;
    }
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #aca499;
    color:#fff;
    flex-direction: column;
  }
}
#contact-form{
  z-index:500;
}
.contact-form{
  
  a{
    color:#fff;
  }
  text-align:left;
  color:#fff;
  input,textarea{
    background-color: #aca499;
    // background-color: #0a101b;
    opacity: 0.4;
    padding:0.7rem;
    border:none;
    color:#fff;
    &::placeholder{
      color:#fff;
      opacity:1
    }
    &:focus{
      opacity:1;
    }
  }
}
.container-fluid.no-padding{
  padding:0;
}
.two-column-banner.banner{
  padding:0;
}
.button.black{
  float:right;
  // background:#2a2828;
  background:#aca499;
  color:#fff;
  @media (min-width: 767.98px) {
    padding:1rem;
  }
  
  padding-left:3rem;
  padding-right:3rem;
  &.disabled{
    opacity: 0.4;
  }
  /* width:150px; */
}
.banner .banner-text{
  z-index:50;
  text-align:left;
  // padding:2rem;
  // @media (min-width: 700px) and (orientation: landscape) {
  //   padding:5rem; 
  // }
}

</style>

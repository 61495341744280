<template>
  <div class="selling">
    <Nav @changeState="showSideBar=!showSideBar" :showSideMenu="true"/>
    <NavHiddenSideBar v-if="showSideBar" @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    <div id="nav-hidden" class="container-fluid navbar sticky-top desktop" style="display:none;">
      <NavHidden :menu="content.menu"/>
    </div>
    <div class="carousel-container">
      <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="overlay d-flex align-items-center justify-content-center">
                  <div class="banner-overlay"></div>
                  <img :src="require(`@/assets/newbg.jpg`)" />
                  <div class="teaser-container col-md-8 col-10">
                    <h3 class="h3-animation"><span>Hoppsan, sidan kunde inte hittas</span></h3>
                    <p>
                      Det kan bero på att sidan kan ha bytt namn eller flyttats. Vi ber om ursäkt för det! 
                      Det kan också bero på att du av misstag angett fel adress, det kan lätt hända. 
                      Kontrollera stavningen högst upp i adressfältet och försök igen eller försök att söka efter sidan
                    </p>
                    <p>
                      Du kan också kontakta oss om du behöver hjälp med att hitta rätt.
                    </p>
                    <a href="#contact-form" class="button">Kontakta oss</a>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>

    
    <ContactForm msg="Kontakta oss" :content="content"/>
    <Footer :footer="content.footer"/>
  </div>
</template>

<script>
//import BrokerPuff from '@/components/BrokerPuff.vue'
import ContactForm from '@/components/ContactForm.vue'
import NavHidden from '@/components/NavHidden.vue'
import NavHiddenSideBar from '@/components/NavHiddenSideBar.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
//import { createPopper } from '@popperjs/core';
export default {
  name: 'NotFound',
  components: {
    ContactForm,
    NavHiddenSideBar,NavHidden,Nav,Footer
  },
  props:{
    content:Object,
    title: String,
    description: String
  },
  created:function(){
    //window.location = "https://unikfast.se/404.php"
  },
  data: function(){
    return{
      app_url:process.env.VUE_APP_URL,
      showSideBar:false
    }
  }
}
</script>
<style lang="scss" scoped>
.salja-grid h3 {
    font-size: 25px;
}
.salja-grid h3 span {
    color: #DC911B;
    font-size: 15px;
    display: inline-block;
    margin-right: 7px;
    position: relative;
}
</style>

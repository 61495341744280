<template>
  <div class="home">
    <Search v-if="showSearch" :content="content"></Search>
    <Nav @changeState="showSideBar=!showSideBar" :showSideMenu="true" :content="content" />
    <NavHiddenSideBar v-if="showSideBar" @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    <div id="nav-hidden" class="container-fluid navbar sticky-top" style="display:none;">
      <NavHidden @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    </div>
    <div class="carousel-container">
      <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="overlay d-flex align-items-center justify-content-center" style="background-color:#131625;">
              <!-- <div class="banner-overlay"></div> -->
              <!-- <img v-if="content.apiId=='sthlm'" src="https://unikfast.se/cms/wp-content/uploads/2023/05/Unik-Gruppbild-Stokholm-2-scaled.jpg" /> -->
              <!-- <img v-if="content.apiId == 'sthlm'" :src="require(`@/assets/44.jpg`)"/> -->
              <div class="teaser-container col-md-8 col-10">
                <h3 class="h3-animation text-uppercase"><span>{{statics['Vårt']}}</span> <span>{{statics['Team']}}</span></h3>
                <p class="d-none d-md-block" v-if="pageData" v-html="pageData.content"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row listing justify-content-center mx-md-5 mb-3 d-block d-md-none">
          <div class="col-12 mt-3">
            <p v-if="pageData" v-html="pageData.content"></p>
          </div>
      </div>
      <div class="row listing justify-content-center mx-md-5 mb-3">
          <div class="col-md-5 col-12 mt-3"  v-for="br in wp_brokers" :key="br.title">
            <BrokerPuffWp :broker="br" :content="content"/>
          </div>
      </div>
    </div>
    <ContactForm :content="content"/>
    <Footer :footer="content.footer"/>
  </div>
</template>

<script>
import BrokerPuffWp from '@/components/BrokerPuffWp.vue'
import Search from '@/components/Search.vue'
//import BrokerPuff from '@/components/BrokerPuff.vue'
import ContactForm from '@/components/ContactForm.vue'
const axios = require('axios');
import NavHidden from '@/components/NavHidden.vue'
import NavHiddenSideBar from '@/components/NavHiddenSideBar.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
//import { createPopper } from '@popperjs/core';
export default {
  name: 'AboutUs',
  components: {
    ContactForm,BrokerPuffWp,
    NavHiddenSideBar,NavHidden,Nav,Footer
    ,Search
  },
  props:{
    content:Object,
    title: String,
    description: String
  },
  data: function(){
    return{
      app_url:process.env.VUE_APP_URL,
      brokers:[],
      wp_brokers:[],
      showSideBar:false,
      statics:{},
      showSearch:false,
      pageData:null
      //aboutText:""
    }
  },
  created: function(){
    var statics = require('@/helpers/lang_'+this.content.lang);
    this.statics = statics.lang;
    this.getCustomBrokers();
    this.getPageContent();
  },methods: {
    getPageContent(){
  let self = this;
  const pageSlug = 'om-oss'; // replace with your actual page slug

  axios.post('https://easypress.se/wafast/graphql', {
      query: `{
        pages(where: {name: "${pageSlug}"}) {
          nodes {
            title,
            slug,
            content,
            featuredImage {
              node { id, sourceUrl }
            }
          }
        }
      }`
  }).then(function(response) {
    if(response.data.data.pages.nodes && response.data.data.pages.nodes.length == 1){
      self.pageData = response.data.data.pages.nodes[0];
    }
      
  });

},

    getCustomBrokers(){
      //var self = this;
      //let categoryId = 2;
        // axios.post('https://unikfast.se/cms/graphql', {
        //   query: "{employees(first:50,where:{categoryIn:"+categoryId+"}){ nodes{title,slug,content,medarbetareInformation{phone,email,title,vitecUser,profileImage{id,srcSet,sourceUrl},headerImage{id,srcSet,sourceUrl},teaserImage{id,srcSet,sourceUrl}},featuredImage{node{id,sourceUrl}}} }}"
        // }).then(function(response){
        //     self.wp_brokers = response.data.data.employees.nodes;
        // });
        var self = this;
      let categoryId = 12;
        axios.post('https://easypress.se/wafast/graphql', {
          query: "{posts(first:50,where:{categoryIn:"+categoryId+"}){ nodes{title,slug,content,medarbetareInformation{phone,email,title,vitecUser,profileImage{id,srcSet,sourceUrl},headerImage{id,srcSet,sourceUrl},teaserImage{id,srcSet,sourceUrl}},featuredImage{node{id,sourceUrl}}} }}"
        }).then(function(response){
            self.wp_brokers = response.data.data.posts.nodes;
        });
    },
    getBrokers(){
      var self = this;
      const url = process.env.VUE_APP_URL+"/api/"+self.content.apiId+"/brokers/get_brokers";
        axios.get(url)
        .then(function(response){
          self.brokers = response.data;
          // self.brokers.sort(function (a, b) {
          //   if (a.order > b.order) {
          //     return 1;
          //   }
          //   if (a.order < b.order) {
          //     return -1;
          //   }
          //   // a must be equal to b
          //   return 0;
          // });
        });
    }
  }
}
</script>
<style lang="scss" scoped>
.overlay{
  min-height: 400px;
  @media (min-width: 768px) {
    min-height: 600px;
  }
  height: auto;
  background-color:#fff;
  img{
    object-position:bottom;
    object-fit:cover;
  }
}
.listing{
  display: flex;
  flex-wrap:wrap;
}
.broker-image{
  img{
    height: 400px;
  }
}

</style>

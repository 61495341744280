<template>
<div v-if="listing.bids != null && listing.assignment.status.id != sold && listing.assignment.status.id != soldReference" class="viewings">
  <div v-if="listing.internetSettings.bidSetting != 'DontShowBidding'" class="label label-bid">Budgivning pågår!</div>
  <div class="date row" v-if="listing.internetSettings.bidSetting == 'ShowBiddhistory' && listing.internetSettings.bidSetting != 'DontShowBidding'">
    <div class="col-6">Bud</div>
    <div class="col-6">Datum</div>
  </div>
  <template v-for="(bid,index) in listing.bids">
    <div class="date row" :key="bid.id" v-if="(index < 4 || showAllBids) && listing.internetSettings.bidSetting == 'ShowBiddhistory' && listing.internetSettings.bidSetting != 'DontShowBidding' && !bid.cancelled">
      <div class="col-6">
        <span>{{bid.alias}}) </span><span>{{NumberFormat(bid.amount)}} kr</span>
      </div>
      <div class="col-6 info-label">
        {{formatDate(bid.dateAndTime)}} {{GetTime(bid.dateAndTime)}}
      </div>
    </div>
  </template>
  <template v-if="(listing.internetSettings.bidSetting == 'ShowHighestBid' && listing.bids.length > 0)">
    <div class="date row" >
      <div class="col-6">
        <span>{{NumberFormat(getHighestBid(listing.bids).amount)}} kr</span>
      </div>
      <div class="col-6 info-label">
        {{formatDate(getHighestBid(listing.bids).dateAndTime)}} {{GetTime(getHighestBid(listing.bids).dateAndTime)}}
      </div>
    </div>
  </template>
  <div class="date row expand-rows" v-if="listing.internetSettings.bidSetting == 'ShowBiddhistory' && listing.internetSettings.bidSetting != 'DontShowBidding'">
    <span v-if="listing.bids.length > 3 && !showAllBids" v-on:click="showAllBids=!showAllBids">Visa alla bud</span>
    <span v-if="listing.bids.length > 3 && showAllBids" v-on:click="showAllBids=!showAllBids">Dölj bud</span>
  </div>
</div>
</template>
<script>
export default {
  name: 'BiddingBox',
  props: {
    listing:Object,
    content:Object
  },
  data: function(){
    return{
      showAllBids: false,
      forSale:"3",
      sold:"4",
      soldReference:"10",
      comming:"2",
    }
  },
  created: function(){
    if(this.content.apiId == "umea"){
      //this.getBroker();
      this.forSale = "2HIII41T24FNHOU2";
      this.sold = "2HIII4T0O9LMQ3UI";
      this.status = this.forSale;
    }
  },
   methods: {
    NumberFormat(sum){
      return Number(sum).toLocaleString('sv');
    },
    formatDate(timestamp) {
      return new Date(timestamp).toLocaleDateString('sv-SE');
    },
    getHighestBid(bids){
      for(let bid of bids){
        if(!bid.cancelled) return bid;
      }
    },
    GetTime(date){
      var datetime = new Date(date);
      return datetime.getHours()+':'+(datetime.getMinutes()<10?'0':'') + datetime.getMinutes();
    },
    }
}
</script>

<template>
  <div class="footer container-fluid">
    <div class="row d-flex align-items-center justify-content-center">
      <div class="col-10 pb-2">
        <img  class="logo" :src="require(`@/assets/logo/WA-Logo-White-Long.svg`)">
      </div>
    </div>
    <div class="row d-flex align-items-center justify-content-center">
      
      <div class="col-10 row pt-2 justify-content-center" v-if="footer.id == 'sthlm'">
        <div class="col-md-4 order-first order-md-0">
          <p>
            Hagaesplanaden 60<br>113 66 Stockholm<br>
            <a target="_blank" href="https://www.instagram.com/wikingskioldaspelin" class="text-white rounded-circle instagram"><i class="fa-lg bi bi-instagram" ></i></a>
          </p>
        </div>
        
      </div>
      <div class="col-10">
        <ul class="list-unstyled nav justify-content-center flex-column flex-md-row">
          <li>© Wikingskiold / Aspelin AB</li>
          <li>info@wafast.se</li>
          <li v-if="footer.id=='sthlm'">070 - 999 30 02</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String,
    footer:Object
  },
  components:{
    
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/globals/styles/colorPalette.scss";
.list-unstyled{
  list-style-type: none;
  li{
    float:left;
  }
  i{
  font-size:14pt;
  }
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
  li {
  margin: 0 10px;
  }
}

i,a{
  // color:#fff;
  // color:#d09658;
  color:#fff;
  width:1em;
}
.logo{
  width:200px;
}
.footer{
  // background-color: #0a101b;
  background-color:$main-dark-beige;
  font-size:10pt;
  color:#fff;
  // color:#d09658;
  /* height:500px; */
  width:100%;
  padding:1rem;
  z-index:1000;
  position: relative;
}

</style>

<template>
  <div class="selling">
    <Search v-if="showSearch" :content="content"></Search>
    <Nav @changeState="showSideBar=!showSideBar" :showSideMenu="true"/>
    <NavHiddenSideBar v-if="showSideBar" @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    <div id="nav-hidden" class="container-fluid navbar sticky-top" style="display:none;">
      <NavHidden @changeState="showSideBar=!showSideBar" :menu="content.menu"/>
    </div>
    <div class="carousel-container">
      <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="overlay d-flex align-items-center justify-content-center">
                  <div class="banner-overlay"></div>
                  <!-- <img :src="require(`@/assets/slide3.jpg`)" /> -->
                  <!-- <img src="https://unikfast.se/cms/wp-content/uploads/2023/09/nedladdning-1-scaled.jpeg" /> -->
                  <img :src="require(`@/assets/44.jpg`)"/>
                  <div class="teaser-container col-md-8 col-10">
                    <h3 class="h3-animation"><span>Säljguide</span></h3>
                    <p class="d-block w-100 text-center">
                      Vår säljprocess är anpassad så att våra kunder ska känna att vi tar hand om objektet på ett
tryggt och seriöst sätt. Processen är i sex enkla steg och gör så att vi inte lämnar något åt
slumpen.
                    </p>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>
    <div class="container py-4">
      
      <div class="salja-grid row">
        <div class="col-md-6 col-12 order-2 order-md-1">
          <img class="w-100 py-5" src="https://easypress.se/wafast/wp-content/uploads/sites/19/2024/02/Bild-1-torsplan-scaled.jpg" />
          <img class="w-100" src="https://easypress.se/wafast/wp-content/uploads/sites/19/2024/02/DSCF7983-HDR-scaled.jpg" />
        </div>
        <div class="col-md-6 col-12 row d-block pt-5 order-1 order-md-2">
          <div class="col-12">
            <h3> <span> 1. </span> Värdering </h3>
            <p> Vi genomför ett besök för att bestämma marknadsvärdet av objektet. Vi går tillsammans
            igenom läge, skick och utvecklingspotential, allt för att hitta de bästa förutsättningarna för
            just dig. Givetvis är detta helt kostnadsfritt.</p>
          </div>
        <div class="col-12">
          <h3> <span> 2. </span> Styling och konsultation </h3>
                  <p>Vi erbjuder dig ett besök av en professionell stylist som berättar hur vi kan få ut ett högre
          värde av bostaden. Vår erfarenhet är att detta verkligen bidrar till en mer lyckad försäljning.</p>
        </div>
  <h3> <span> 3. </span> Marknadsföring </h3>
          <p>Ju fler spekulanter som dyker upp på visningen, desto högre försäljningspris. Därför är det
  viktigt att ha en väl uttänkt marknadsföringsstrategi inför försäljningen. Vi tar fram ett skarpt
  förslag som vi därefter beslutar om tillsammans. Även vår fotograf är noga vald, då även det
  har en stor påverkan på försäljningsresultatet.</p>
  <h3> <span> 4. </span> Visning </h3>
          <p>Visningen ska hjälpa spekulanterna att känna att just din bostad är deras framtida
  drömboende – och här är vi experter. Det som särskiljer oss från många, är att vi kommer väl
  förberedda och även har studerat alla fördelar med objektet och närområdet. Samt att vi
  inte är blyga att förmedla informationen under visningen.</p>

  <h3> <span> 5. </span> Budgivning </h3>
          <p>Den pirrigaste och många gånger roligaste delen. Vår målsättning är att vår kunskap och våra
  förslag skall ge våra budgivare en tryggare process, vilket leder till fler budgivare, och
  därmed ett högre försäljningspris.</p>

  <h3> <span> 6. </span> Kontraktsskrivning &amp; tillträde </h3>
          <p>Kontraktsskrivning och tillträde
  Kontraktet är inte bara det dokument som avslutar budgivningen, det är också det som
  reglerar hela försäljningen. Samtliga parter; säljare, köpare och mäklare, kommer
  tillsammans överens om tillträde, betalningsvillkor och överlämning.</p>
        </div>
       
		</div>
    </div>
    
    <ContactForm :content="content"/>
    <Footer :footer="content.footer"/>
  </div>
</template>
<style lang="scss" scoped>
.salja-grid h3 {
    font-size: 25px;
    // display: flex;
    //  font-family: 'Adamina', serif;
}
.salja-grid h3 span {
    color: #DC911B;
    font-size: 15px;
    display: inline-block;
    margin-right: 7px;
    position: relative;
    // top: 14px;
    // font-family: 'Adamina', serif;
}
</style>
<script>
import Search from '@/components/Search.vue'
//import BrokerPuff from '@/components/BrokerPuff.vue'
import ContactForm from '@/components/ContactForm.vue'
import NavHidden from '@/components/NavHidden.vue'
import NavHiddenSideBar from '@/components/NavHiddenSideBar.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
//import { createPopper } from '@popperjs/core';
export default {
  name: 'AboutUs',
  components: {
    ContactForm,
    NavHiddenSideBar,NavHidden,Nav,Footer,Search
  },
  props:{
    content:Object,
    title: String,
    description: String
  },
  data: function(){
    return{
      app_url:process.env.VUE_APP_URL,
      brokers:[],
      showSideBar:false,
      showSearch:false
    }
  },
  created: function(){
    //this.getBrokers();
  },methods: {
    // getBrokers(){
    //   var self = this;
    //   const url = process.env.VUE_APP_URL+"/api/brokers/get_custom_brokers";
    //     axios.get(url)
    //     .then(function(response){
    //       self.brokers = response.data;
    //       self.brokers.sort(function (a, b) {
    //         if (a.order > b.order) {
    //           return 1;
    //         }
    //         if (a.order < b.order) {
    //           return -1;
    //         }
    //         // a must be equal to b
    //         return 0;
    //       });
    //     });
    // }
  }
}
</script>
<style lang="scss" scoped>
.item{
  position:relative;
}
  .item{
        &:before{
      content: "";
        display: block;
        position: absolute;
        top: -55px;
        left: 50%;
        height: 2.5625rem;
        border-right: 1px solid #0a0a0a;
        transform: translateX(-50%);
    }
  }


.listing{
  display: flex;
  flex-wrap:wrap;
}
.broker-image{
  img{
    height: 400px;
  }
}

</style>
